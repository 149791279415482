module.exports = responsive = {
  setHtmlFontSize() {
    const htmlDom = document.getElementsByTagName("html")[0];
    let htmlWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    let fontsize = "62.5%";
    if (htmlWidth > 2000) {
      fontsize = "75%";
    } else if (htmlWidth > 1200) {
      fontsize = "62.5%";
    } else if (htmlWidth > 900) {
      fontsize = "56.25%";
    } else {
      fontsize = "50%";
    }

    htmlDom.style.fontSize = fontsize;
  },
};

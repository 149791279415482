export default {
  "menu": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
    "trails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trails"])},
    "contribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contribuer"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communauté"])},
    "us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A propos"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["se connecter"])},
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s'inscrire"])}
  }
}
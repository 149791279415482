export default {
  "error": {
    "acceptConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must accept our general conditions before signing up"])},
    "passwordIdentical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your passwords are not identical"])},
    "passwordLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password must be at least 8 characters"])}
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log In"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find hiking partner, trail veterans and information about trails!"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["log in"])},
    "switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not yet a member of the community ? → <span class='u-color-blue-light'>Sign up</span> here"])}
  },
  "signup": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join our thru-hiking community"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
    "switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already member of the community ? →  <span class='u-color-blue-light'>Log in</span> here"])}
  },
  "form": {
    "email": {
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email"])}
    },
    "password": {
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password"])}
    },
    "passwordConfirm": {
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password again"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password again"])}
    }
  },
  "passwordForgotten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password forgotten"])},
  "success": {
    "loggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are logged in !"])},
    "signUp": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You are signed up. An email has been sent to ", _interpolate(_named("email")), ". Please confirm your account asap."])}
  },
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact us"])}
}
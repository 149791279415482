export default {
  "popup": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete comment"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARE YOU SURE YOU WANT TO DELETE THIS comment ?"])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["your message has been deleted"])}
  },
  "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reply"])},
  "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hide"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete"])}
}
<template>
    <div>
        <div class="popup__header popup__header--blue">
            <h2 class="popup__header--title">
                Post preview
            </h2>
        </div>
        <div class="popup__main" v-if="post">
            <post-content :post="post" :countComments="0" class="popup__post" > </post-content>
            <div class="popup__action">
              <button class="btn btn--blue-i popup__action--item" @click="$store.state.popup.display = false">
                  Close
              </button>
          </div>
        </div>
    </div>

</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';

import PostContent from '../post/PostContent';

export default {
    components: {PostContent},
    setup(){
        const store = useStore();
        const data = ref(store.state.popup.data.data);

        const post = ref(null);

         const getPost = async () => {
             console.log(data.value);
            try{
                const res = await axios({
                    method:'GET',
                    url:'posts/' +data.value.slug
                })

                console.log(res);
                post.value = res.data.post;
                // console.log(post.value);

            }catch(err){
                console.log(err.response);
            }
        }
        getPost();

        return {
            data,
            post
        }
    }

}
</script>

<style lang="scss" scoped>
@import '../../style/main';

@import '../../style/components/popup';

.popup{
    &__post{
        width: 100%;
        padding: 5rem;
    }
}

</style>
<template>
  <div v-if="displayCookieConsent" class="cookies">
        <button class="cookies__delete" @click="setCookieConsent(false)">
            <Svg className="cookies__delete--svg" icon="delete"/>
        </button>
    <div class="cookies__inner">
        <div class="cookies__copy">
            <div class="cookies__header">
                This website uses cookies
            </div>
            <div class="cookies__description">
                    We use cookies to personalise content, to provide social media features and to analyse our traffic. 
                    We also share information about your use of our site with our analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services. 
                    You consent to our cookies policy if you continue to use our website.
                </div>
            </div>
            <div class="cookies__actions">
                <button class="cookies__cta" @click="setCookieConsent(true)">
                    OK
                </button>
                <button class="cookies__cta cookies__cta--secondary" @click="setCookieConsent(false)">
                    Decline
                </button>
            </div>
        </div>
    </div>

</template>

<script>
import { ref } from 'vue';
import Svg from '../utils/Svg';
export default {
    components:{
        Svg
    },
    setup(){
        const displayCookieConsent = ref(true);

        const setCookieConsent = (value) => {
            $cookies.set('cookie-consent', value, "3M");
            displayCookieConsent.value = false;
        }
        return {setCookieConsent, displayCookieConsent};
    }
}
</script>

<style lang="scss">
@import '../../style/main';
.cookies {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2147483645;
  width: 100%;

  background-color: #f1f6f4;
  &__inner {
    max-width: $page-max-width;
    margin: 0 auto;
    padding: 2.4rem;
  }
  &__copy {
    margin-bottom: 16px;
  }
  &__actions {
    display: flex;
    @include respond(phone) {
      display: flex;
      flex-wrap: wrap;
    }
  }
  &__delete {
    @include buttonNoStyle;
    position: absolute;
    top: 1rem;
    right: 1rem;
    &--svg {
      @include svg(1.6rem);
    }
    transition: all 0.2s;
    &:hover {
      color: $color-red;
    }
  }
  &__header {
    margin-bottom: 0.8rem;

    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.5rem;
    line-height: 24px;
    letter-spacing: 0.2rem;
  }
  &__description {
    font-weight: normal;
    color: #838f93;
    font-size: 1.5rem;

    @include respond(phone) {
      text-align: justify;
    }
  }
  &__cta {

    @include buttonNoStyle;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 164px;
    padding: 0.7rem 2.2rem;

    box-shadow: $shadow-light;

    border-radius: 3px;

    background-color: $color-green;

    color: #fff;
    text-align: center;
    font-weight: normal;
    font-size: 1.6rem;
    text-transform: uppercase;
    transition: all 0.2s;

    @include respond(phone) {
      padding: 1rem 4rem;
      margin-bottom: 0.6rem;
      min-width: auto;
    }

    &:last-child {
      margin-left: 16px;
    }

    &:hover {
      background-color: darken($color-green, 10);
    }
    &--secondary {
      border: 1px solid currentColor;
      background-color: transparent;
      color: $color-grey-75;
      transition: all 0.2s;
      &:hover {
        border-color: $color-red;
        background-color: $color-red;
        color: $color-white;
      }
    }
  }
}


</style>
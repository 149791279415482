<template>
  	<section class="carousel">
          <index-title :titleData="carouselTitle" :links="links"></index-title>
          <div class="carousel__nav">
            <div class="carousel__nav--content">
              <button id="btn-carousel-trails-left" class="carousel__nav--btn" data-side="left" aria-label="carousel-trails-button-left">
                <Svg className="carousel__nav--btn-svg" icon="arrow-left"></Svg>
              </button>
              <span>
                1
              </span>
              -
              <span id="carousel-trail-max-items">
                {{trailSample.length + 1}}
              </span>
              <button id="btn-carousel-trails-right" class="carousel__nav--btn" data-side="right" aria-label="carousel-trails-button-right">
                <Svg className="carousel__nav--btn-svg" icon="arrow-right"></Svg>
              </button>
            </div>
          </div>
          <div  v-if="trailSample.length" id="carousel-trails" class="carousel__content">
            <div v-for="trail in trailSample" :key="trail.id" class="carousel__item">
              <trail-card :data="trail" :displayMap="false"></trail-card>
            </div>
            <router-link :to="{name:'Trails'}" class="carousel__item carousel__item--trail">
              <div class="index__cta index__cta--trail" :style="`background-image:linear-gradient(to bottom, rgba(255,255,255,.3), rgba(255,255,255,.9)), url(${$store.state.apiUrl}/images/static/background/small/index_trail_cta.webp)`">
                <button class="index__cta--more">
                  <Svg className="index__cta--more-svg" icon="more"></Svg>
                </button>
                <button class="index__cta--button">
                  {{t('cta')}}
                  <Svg className="index__cta--button-svg" icon="arrow-right-2"></Svg>
                </button>
              </div>
            </router-link> 
        </div>
    </section>
</template>

<script>
import {computed, defineAsyncComponent, onMounted, ref} from 'vue';
import Svg from '../utils/Svg';
import TrailCard from '../trails/TrailCard';

import $ from 'jquery';
import slick from 'slick-carousel';

import axios from 'axios';
import moment from 'moment';

import IndexTitle from './IndexTitle';

import {displaySize} from '@/composables/utils/displaySize';
import { useI18n } from 'vue-i18n';


import en from "@/locales/en/index/trails.json";
import fr from "@/locales/fr/index/trails.json";



export default {

   components: {
      IndexTitle,
      Svg,
      TrailCard
  },

    setup(){
          const {t} = useI18n({
            messages:{
                en: en,
                fr: fr
                }
        });

        const links = ref([]);
        const trailSample = ref([]);
         const carouselTitle =  computed(() => {
            return {
            title: t('title'),
            action: t('cta'),
            link: "Trails",
        }})
        


        const createQuickLinks = (ipData) => {
          return [
            {
                name:"Trails in "+ipData.continent,
                svg:false,
                img:false,
                to:{name:'Trails', params:{queryString:'top-trails-in-'+ipData.continent.toLowerCase()}}
            },
             {
                name:"Trails in "+ipData.country,
                img:`${process.env.VUE_APP_API_BASEURL}/images/flag/thumbnail/${ipData.countryCode.toLowerCase()}.webp`,
                to:{name:'Trails', params:{queryString:`top-trails-in-${ipData.country.toLowerCase()}`}}
            },
             {
                name:"Trails during "+ipData.season,
                svg:ipData.season.toLowerCase(),
                to:{name:'Trails', params:{queryString:'top-trails-during-'+ipData.season.toLowerCase()}}
            },
             {
                name:"Trails in "+moment().format('MMMM'),
                to:{name:'Trails', params:{queryString:'top-trails-during-'+moment().format('MMMM').toLowerCase()}}
            }

        ]
        }

        const loadTrailSample = async() => {
          try{
            const data = await axios.get('trails/ip/6');
            trailSample.value  = data.data.data.cards;
            links.value = createQuickLinks(data.data.data.ip);

            // if(data.data.data.ip) carouselTitle.value.title = 'Discover trails in '+data.data.data.ip.country;

            let cardsDisplay = 3;
            const screen = displaySize()
            if( screen === 'phone'){
              cardsDisplay = 1
            }else if(screen === 'tablet'){
              cardsDisplay = 2
            }

            $(function(){
                $("#carousel-trails").slick({
                  infinite: false,
                  slidesToShow: cardsDisplay,
                  slidesToScroll: 1,
                  rows:0,
                  prevArrow: $("#btn-carousel-trails-left"),
                  nextArrow: $("#btn-carousel-trails-right"),
                });
            })
            

          } catch(err){
            console.log(err);
            console.log(err.message);
              // error.value = err.message;
          }
        }
        onMounted(() =>{
          loadTrailSample();
        })
        
        return {
          carouselTitle,
          links,
          t,
          trailSample
        }
    },


}
</script>

<style lang="scss">

@import '@/style/components/slick';

@import '@/style/main';

.carousel{
    // margin: 6rem 0;
    &__nav{
        display: flex;
        margin-bottom: 2rem;
        &--content{
            margin-left: auto;
            font-size: 1.6rem;

            display: flex;

            & span{
                padding: 0 1rem;
            }
        }

        &--btn{
            display: flex;
            align-items: center;

            background: none;
            color: inherit;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;

            padding: 0 1rem;

            transition: all .2s;

            &-svg{
                @include svg(2rem);
            }

            &:hover{
                color:$color-blue-light;
            }
        }
    }

    &__item{
      cursor: pointer;
      list-style: none;
      height: auto ;
      align-self: stretch;
    }

}
</style>
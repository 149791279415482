<template>
   <div id="post-container">
        <h1 class="post__title">
            {{post.locales && post.locales[$store.state.language] && post.locales[$store.state.language].title ? post.locales[$store.state.language].title :post.title}}
        </h1>
        <div class="post__summary">
            <router-link :to="{name:'User', params:{slug: post.createdBy._id}}" class="post__summary--author">
                <img :src="`${$store.state.apiUrl}/images/profil/small/${post.createdBy.profilePicture.split('.')[0]}.webp`" alt="profile picture" class="post__summary--author-img">
                <div class="post__summary--author-name">{{post.createdBy.name}}</div>
            </router-link>
            <div class="post__summary--comments">
                <Svg className="post__summary--comments-svg" icon="messenger"></Svg>
                {{countComments}} {{t('comment', 1)}}
            </div>
        </div>
        <div class="post__content markdown" v-html="post.locales && post.locales[$store.state.language] && post.locales[$store.state.language].content? post.locales[$store.state.language].content: post.content"></div>
        <div class="post__signature">
            <router-link :to="{name:'User', params:{slug:post.createdBy.slug}}" class="post__signature--name">
                {{post.createdBy.name}}
            </router-link>
            {{t('posted')}}
            <router-link :to="{name:'Community', params:{category:post.category}}" class="post__signature--category">
                <Svg className="post__signature--category-svg" :icon="`post-${post.category}`"></Svg>
                {{post.category}}
            </router-link>        
        </div>
    </div>
</template>

<script>

import Svg from '../utils/Svg';

import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import axios from 'axios';
import { onMounted, ref } from '@vue/runtime-core';


import en from "@/locales/en/post/post.json";
import fr from "@/locales/fr/post/post.json";


export default {
    components: {Svg},
    props:['post', 'countComments'],
    setup(props){
        const store = useStore();
        const {t, locale}  = useI18n({
			messages:{
              en: en,
              fr: fr
            }
		})

        onMounted(() => {
            checkAvailableTranslation(props.post);
        })

        //CHECK LOCALES
        const checkAvailableTranslation = async (post) => {
            const language = store.state.language
            if(language !== 'en'){
                const compulsaryFields = ['title'];
                for(let field of compulsaryFields){
                    if( !post.locales || !post.locales[language] || !post.locales[language][field]){
                        try{
                            const res = await axios({
                                method: "POST",
                                url:`translate/post/${field}/${post._id}`
                            })
                            dataTranslate.value[field] = res.data.translation
                        }catch(err){
                            console.log(err);
                            console.log(err.response);
                        }
                    }
                }
            }
        }
        return {t}
    }

}
</script>

<style lang="scss">
@import '@/style/main';
@import '@/style/components/markdown';

.post{
     &__title{
        
        text-transform: uppercase;
        font-size: 2rem;
        letter-spacing: .2rem;
        color:$color-blue;

        border: 3px solid currentColor;
        box-shadow: $shadow-light;

        padding: 1.2rem 2rem;

        margin-bottom: 3rem;

        font-weight: 700;
    }

    &__summary{
        font-style: 1.2rem;
        font-weight: 400;
        display: flex;
        border-bottom: 1px dashed $color-blue-light;
        padding-bottom: 2rem;
        &--author{
            display: flex;
            align-items: center;

            text-transform: uppercase;
            letter-spacing: .1rem;

            color:inherit;
            text-decoration: none;

            &:hover > &-name{
                @include hoverUnderline;
            }

            &-img{
                height: 2.8rem;
                width: 2.8rem;
                margin-right: 1.6rem;
                border-radius: 50%;

            
            }
        }
        &--comments{
            display: flex;
            align-items: center;
            margin-left: auto;

            // font-weight: 700;
            
            font-style: italic;
            text-transform: uppercase;
            &-svg{
                color:$color-blue-light;
                @include svg(2.4rem);
                margin-right: 1.4rem;
            }
        }
    }

    &__content{
        margin: 4rem 0;
        font-size: 1.4rem;    
    }

    
    &__signature{
        position: relative;
        margin-left: 4rem;
        margin-bottom: 4rem;
        font-size: 1.4rem;

        display: flex;
        align-items: center;
        
        &::before{
            content:"";
            position: absolute;
            top: 50%;
            left: -4rem;
            width: 2rem;
            height: .2rem;
            background-color: $color-blue-light;
        } 

        &--name{
            text-transform: uppercase;
            text-decoration: none;
            color:inherit;
            position: relative;

            @include hoverUnderline;

            margin-right: .7rem;
        }

        &--category{
            color: inherit;
            text-decoration: none;
            text-transform: uppercase;
            margin-left: 1rem;
            display: flex;
            align-items: center;
            position: relative;

            @include hoverUnderline;


            &-svg{
                color:$color-blue-light;
                margin-right: .7rem;
                @include svg(2rem);
            }
        }
    }
}

</style>
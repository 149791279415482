<template>
   <div class="hover__info">
        <Svg className="hover__info--svg" icon="infos"/>
        <div class="hover__info--content  hover__info--content-left">
            <h6 class="hover__info--content-text">{{message}}</h6>
        </div>
    </div>
</template>

<script>
import Svg from '../utils/Svg';
export default {
    components: {Svg},
    props:['message']

}
</script>

<style lang="scss">

@import '../../style/main';

.hover {
  &__info {
    margin-left: 1.2rem;
    position: relative;
    cursor: pointer;
    &--svg {
      @include svg(1.4rem);
    }
    &--content {
      position: absolute;

      color: $color-white;

      width: 30vw;
      display: flex;
      visibility: hidden;
      opacity: 0;

      &-left {
        left: 100%;
        top: 50%;
        margin-left: 1rem;
        transform: translateY(-50%);
      }
      &-text {
        background-color: $color-grey-75;
        padding: 0.5rem 1rem;

        border-radius: 5px;
        font-size: 1.1rem;
        // text-transform: none;
        font-weight: 400;
      }
    }
  }
  &__info:hover > &__info--content {
    opacity: 1;
    visibility: visible;
  }
}

</style>
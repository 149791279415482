<template>
   <svg :class="className">
        <use :href="`/img/illustration.svg#${icon}`"></use>
    </svg>
</template>

<script>
export default {
    props: [
        'className', 'icon'
    ],
    setup(){


    }
}
</script>

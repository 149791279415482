export default {
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour au forum"])},
  "card": {
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commentaire"])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir le post"])},
    "like": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["j'aime"])},
    "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a publié dans"])}
  },
  "posted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a publié dans"])},
  "comment": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["commentaire"]), _normalize(["commentaire"]), _normalize(["commentaires"])])},
  "commentPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ecrivez un commentaire"])},
  "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["publier votre commentaire"])},
  "reactions": {
    "endorse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivre"])},
    "love": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'adore"])},
    "fun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marrant"])},
    "wow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wow"])},
    "sad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Triste"])}
  }
}
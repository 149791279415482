<template>
  <div>
    <div
      id="navigation"
      class="nav"
      :class="{ nav__up: !$store.state.navDisplay }"
      @mouseleave="userMenu = false"
    >
      <div class="nav__container">
        <router-link :to="{ name: 'Home' }" class="nav__logo">
          <Svg :className="'nav__logo--svg'" :icon="'hiker'"></Svg>
          <span>Hike On</span> Earth
        </router-link>
        <div class="nav__content">
          <div class="nav__content--item">
            <router-link
              :to="{ name: 'Home' }"
              class="nav__button"
              @mouseover="hideNav"
              >{{ t("menu.home") }}</router-link
            >
          </div>
          <div class="nav__content--item">
            <router-link
              :to="{ name: 'Trails' }"
              class="nav__button"
              @mouseover="hideNav"
              >{{ t("menu.trails") }}</router-link
            >
          </div>
          <div class="nav__content--item">
            <router-link
              :to="{ name: 'Contribute' }"
              class="nav__button"
              @mouseover="hideNav('contribution')"
              >{{ t("menu.contribute") }}</router-link
            >
            <div
              v-if="displayHiddenMenu.contribution && $store.state.loggedIn"
              class="nav__sub"
              @mouseleave="hideNav"
            >
              <router-link
                v-for="contrib in dataContrib"
                :to="`/contribution/${contrib.page}`"
                class="nav__sub--item"
                @click="hideNav"
              >
                <Svg
                  :className="'nav__sub--item-svg'"
                  :icon="contrib.svg"
                ></Svg>
                {{ contrib.text }}
              </router-link>
            </div>
          </div>

          <div v-if="!$store.state.loggedIn" class="nav__connect--container">
            <button
              id="btn-signup"
              class="nav__connect nav__connect--signup"
              @click="$store.commit('showSignupPopup')"
            >
              {{ t("menu.signup") }}
            </button>
            <button
              id="btn-login"
              class="nav__connect nav__connect--login"
              @click="$store.commit('showLoginPopup')"
            >
              {{ t("menu.login") }}
            </button>
          </div>
          <div v-else class="nav__profil">
            <router-link
              :to="{ name: 'Me' }"
              class="nav__profil--btn"
              aria-label="your hike on earth profile"
              @mouseover="hideNav('user')"
            >
              <Svg
                :className="'nav__profil--btn-svg'"
                :icon="'nav-profil'"
              ></Svg>
            </router-link>
            <div
              v-if="displayHiddenMenu.user"
              class="nav__sub"
              @mouseleave="hideNav"
            >
              <div v-for="menu in dataUserMenu" :key="menu.svg">
                <router-link
                  v-if="
                    menu.to && !menu.protect.includes($store.state.user.role)
                  "
                  :to="menu.to"
                  class="nav__sub--item"
                  @click.stop="hideNav"
                >
                  <Svg :className="'nav__sub--item-svg'" :icon="menu.svg"></Svg>
                  {{ menu.text }}
                </router-link>
                <button
                  v-if="menu.action === 'logout'"
                  class="nav__sub--item"
                  @click="logout"
                >
                  <Svg :className="'nav__sub--item-svg'" :icon="menu.svg"></Svg>
                  {{ menu.text }}
                </button>
              </div>
            </div>
          </div>
          <button
            class="nav__menu"
            aria-label="open lateral menu"
            @click="toggleSideNav"
            @mouseover="hideNav"
          >
            <span class="nav__menu--line">&nbsp;</span>
          </button>
          <router-link
            :to="{ name: 'Feedback' }"
            class="nav__feedback mousehover mousehover--bottom"
            data-hover="suggestion box"
            aria-label="give your feedback"
            @mouseover="hideNav"
          >
            <Svg :className="'nav__feedback--svg'" :icon="'idea'"></Svg>
          </router-link>
          <div class="nav__lang u-phone-hide">
            <button
              class="btn__text nav__lang--item"
              @click="setLanguage('fr')"
            >
              Fr
            </button>
            <button
              class="btn__text nav__lang--item"
              @click="setLanguage('en')"
            >
              En
            </button>
          </div>
        </div>
      </div>
    </div>
    <SideNavigation
      v-if="displayHiddenMenu.sideMenu"
      @closeSideNav="toggleSideNav"
    />
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch, watchEffect } from "vue";

import SideNavigation from "./SideNavigation";
import Svg from "../utils/Svg";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import { logout } from "@/composables/authentication/logout";
import { useI18n } from "vue-i18n";

import { changeLanguage } from "@/composables/languages.js";

import en from "@/locales/en/navigation.json"
import fr from "@/locales/fr/navigation.json"


    const { t } = useI18n({
      messages: {
        en: en,
        fr: fr,
      },
    });

    const store = useStore();

    const navBar = ref(store.state.navDisplay);
    const route = useRoute();

    const setLanguage = (lang) => {
      changeLanguage(store, route, lang);
    };

    const displayHiddenMenu = ref({
      sideMenu: false,
      contribution: false,
      user: false,
    });

    onMounted(() => {
      var c,
        currentScrollTop = 0;
      const navElem = document.getElementById("navigation");
      window.addEventListener("scroll", function() {
        const a = document.documentElement.scrollTop;
        const b = navElem.offsetHeight;
        currentScrollTop = a;

        if (c < currentScrollTop && a > b + b) {
          store.state.navDisplay = false;
        } else if (c > currentScrollTop && !(a <= b)) {
          store.state.navDisplay = true;
        }
        c = currentScrollTop;
      });
    });

    const hideNav = (show = false) => {
      for (let [k, v] of Object.entries(displayHiddenMenu.value)) {
        if (show && k === show) {
          displayHiddenMenu.value[k] = true;
        } else {
          displayHiddenMenu.value[k] = false;
        }
      }
    };

    const toggleSideNav = () => {
      displayHiddenMenu.value.sideMenu = !displayHiddenMenu.value.sideMenu;
    };

    const dataContrib = [
      {
        type: "btn-text",
        svg: "trail",
        text: "Add new trail",
        link: "Contribution",
        page: "addtrail",
        protect: [],
      },
      {
        type: "btn-text",
        svg: "post-all",
        text: "Ask a question",
        link: "Contribution",
        page: "newpost",
        protect: [],
      },
    ];

    const dataUserMenu = [
      {
        type: "btn-text",
        svg: "nav-profil",
        text: "Profile",
        to: {
          name: "Me",
          params: {
            page: "profile",
          },
        },
        protect: [],
      },
      // {
      //     "type"      :  "btn",
      //     "svg"       :  "messenger",
      //     "text"      :  "messenger",
      //     "notification"  :  "0",
      //     "link"      :  "Me",
      //     "display_type"  :  [0,1,2]
      //  },
      // {
      //     "type"      :  "btn",
      //     "svg"       :  "notif",
      //     "text"      :  "notification",
      //     "notification"  :  "0",
      //     "link"      :  "Me",
      //     "display_type"  :  [0,1,2]
      // },
      {
        type: "btn",
        svg: "valid",
        text: "moderation",
        to: {
          name: "Moderation",
          params: {
            page: "trails",
          },
        },
        notification: "0",
        protect: ["user"],
      },
      // {
      //     "type"      :  "btn",
      //     "svg"       :  "cart",
      //     "text"      :  "eshop",
      //     "notification"  : 0,
      //     "link"      :  "Me",
      //     "display_type"  :  [2]
      // },
      {
        type: "btn",
        svg: "admin",
        text: "admin",
        to: {
          name: "Admin",
          params: {
            page: "users",
          },
        },
        notification: 0,
        protect: ["user"],
      },
      // {
      //           "type"      :  "btn",
      //           "svg"       :  "email",
      //           "text"      :  "mailing",
      //           "link"      :  "Me",
      //           "display_type"  :  [1,2]
      // },
      // {
      //           "type"      :  "btn",
      //           "svg"       :  "development",
      //           "text"      :  "development",
      //           "link"      :  "Me",
      //           "display_type"  :  [1,2]
      //       },
      {
        type: "btn",
        svg: "gear",
        text: "Settings",
        to: {
          name: "Me",
          params: {
            page: "editme",
          },
        },
        protect: [],
      },
      {
        type: "btn",
        svg: "logout",
        text: "Logout",
        display_type: [0, 1, 2],
        action: "logout",
      },
    ];
</script>

<style lang="scss">
@import "@/style/main";

.nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $nav-height;
  background-color: $color-white;
  z-index: 5000;
  padding: 1rem 1.6rem;

  color: $color-grey-75;
  box-shadow: $shadow;
  max-width: 100%;

  transition: all 0.3s;

  &__up {
    transform: translateY(calc(-#{$nav-height} - 1rem));
  }

  &__container {
    display: flex;
    margin: 0 auto;
    width: 100%;
    max-width: $page-max-width;
  }

  &__lang {
    margin-left: 2rem;
    border-left: 1px solid currentColor;
    padding-left: 1rem;
    display: flex;
    flex-direction: column;

    &--item {
      text-transform: uppercase;
      &:not(:last-child) {
        margin-bottom: 0.6rem;
      }
    }
  }

  &__logo {
    font-size: 2rem;
    letter-spacing: 0.2rem;
    font-weight: 700;
    margin-right: auto;
    color: $color-blue;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    text-decoration: none;
    position: relative;

    &--svg {
      @include svg(3rem);
      margin-right: 1.2rem;
      @include respond(phone) {
        @include svg(2rem);
        margin-right: 0.6rem;
      }
    }
    @include respond(phone) {
      font-size: 1.6rem;
      letter-spacing: 0.1rem;
    }

    & span {
      font-weight: 300;
      margin-right: 0.6rem;
      white-space: nowrap;
    }

    &--img {
      height: 3rem;
      width: auto;
      margin-right: 1.5rem;
    }
  }
  &__content {
    display: flex;
    align-items: center;
    flex-direction: row;
    align-self: stretch;

    &--item {
      position: relative;
      align-self: stretch;
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-right: 1.4rem;
      }

      &:empty {
        display: none;
      }
    }
  }
  &__button {
    position: relative;
    display: flex;
    flex-direction: column;
    color: inherit;

    align-items: center;

    text-transform: uppercase;
    font-size: 1.3rem;
    letter-spacing: 0.1rem;

    text-decoration: none;
    @include hoverUnderline;

    &--svg {
      @include svg(2rem);
      margin-bottom: 0.4rem;
    }

    &:hover {
      color: $color-blue-light;
    }

    @include respond(phone) {
      display: none;
    }
  }

  &__connect {
    height: 4rem;
    // width: 10rem;
    padding: 0 2rem;
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.2rem;
    text-decoration: none;
    color: inherit;
    white-space: nowrap;

    border-radius: 3px;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: all 0.2s ease-in-out;

    outline: none;
    border: none;

    cursor: pointer;

    @include respond(phone) {
      height: 3.2rem;
      padding: 0 1.4rem;
      font-size: 1.2rem;
    }

    @include respond(small) {
      display: none;
    }

    &:not(:last-child) {
      margin-right: 1rem;
    }

    &--container {
      display: flex;
    }

    &--signup {
      background-color: $color-blue;
      color: $color-grey-10;
      // background-image: linear-gradient(to right bottom, $color-blue, $color-blue-light);
      // background-image: linear-gradient(to right bottom, $color-orange-dark, $color-orange-middle);
      &:hover {
        background-image: linear-gradient(
          to right bottom,
          $color-orange-dark,
          $color-orange-middle
        );
        // background-image: linear-gradient(to right bottom, $color-loved, $color-red-meteo);
      }
    }
    &--login {
      border: 1px solid currentColor;
      background-color: $color-white;
      color: $color-blue;

      &:hover {
        border-color: $color-blue-light;
        background-color: $color-blue-light;
        color: $color-white;
      }
    }
  }
  &__menu {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    display: flex;
    align-items: center;
    margin-left: 2rem;
    height: 4rem;

    @include respond(phone) {
      margin-left: 0.6rem;
    }
    &--line {
      position: relative;

      &,
      &::before,
      &::after {
        width: 3rem;
        height: 2px;
        background-color: currentColor;
        display: inline-block;
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        left: 0;
        transition: all 0.2s;
      }

      &::before {
        top: -0.8rem;
      }
      &::after {
        top: 0.8rem;
      }
    }
    &:hover {
      color: $color-blue-light;
    }
  }
  &__menu:hover &__menu--line::before {
    top: -1rem;
  }
  &__menu:hover &__menu--line::after {
    top: 1rem;
  }

  &__profil {
    position: relative;
    &--btn {
      height: 4rem;
      font-size: 1.4rem;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 0.2rem;
      text-decoration: none;
      color: inherit;

      border-radius: 3px;

      display: flex;
      align-items: center;
      justify-content: center;

      transition: all 0.2s ease-in-out;

      padding: 1rem;
      &:hover {
        color: $color-blue-light;
      }

      &-svg {
        @include svg(2.8rem);
      }
    }

    // border: 1px solid currentColor;
  }

  &__sub {
    position: absolute;
    top: 5rem;
    right: 0;
    background-color: $color-blue-light;
    width: 20rem;
    padding: 1rem 0rem;
    box-shadow: $shadow;
    display: flex;
    flex-direction: column;

    &--item {
      @include buttonNoStyle;
      width: 100%;

      color: $color-white;
      display: flex;
      align-items: center;

      text-transform: uppercase;
      letter-spacing: 0.1rem;
      font-size: 1.2rem;

      text-decoration: none;
      padding: 1rem 1.6rem;
      &-svg {
        @include svg(2rem);
        margin-right: 1rem;
      }

      &:hover {
        background-color: $color-white;
        color: $color-blue-light;
      }
    }
  }

  &__feedback {
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
    margin-left: 2rem;
    border: 1px solid currentColor;
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: $color-blue-light;
    }

    &--svg {
      @include svg(1.6rem);
    }

    @include respond(phone) {
      display: none;
    }
  }
}
</style>

<template>
  <div>
      <div class="popup__header" :class="`popup__header--${data.type} ${data.subtitle ? 'popup__header--split' :''}`">
          <h2 class="popup__header--title">
            {{data.title}}
          </h2>
          <h4 v-if="data.subtitle" class="popup__header--subtitle">
              {{data.subtitle}}   {{data.uniqueId ? ` - ${data.uniqueId}`: ''}}
          </h4>
      </div>
      <div class="popup__main popup__main--small">
          <div class="popup__text">
              <span>{{data.message}}</span>
              {{data.element}}
          </div>
          <div class="popup__action">
              <button class="btn popup__action--item" :class="`btn--${data.color ? data.color: 'red'}-i`" @click="$store.state.popup.display = false">
                  Cancel
              </button>
              <button class="btn popup__action--item" :class="`btn--${data.color ? data.color: 'red'}`" @click="actionPopup">
                  {{data.submit}}
              </button>
          </div>
      </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import $ from 'jquery';

import axios from 'axios';
export default {
    setup(){
        const store =useStore();

        const data = store.state.popup.data;
        
        
        const actionPopup = async () => {
            try{
                const res = await axios(data.action.request);

                if(res.status == data.action.successStatus){
                    store.state.popup.display = false;
                    store.state.updateData = data.action.success; //You need to create a watcher in order to manage the success of the request
                }
                
            }catch(err){
                console.log(err.response);
            }
        }

        return {actionPopup, data};
    }
}
</script>

<style lang="scss" scoped>
@import '../../style/main';

@import '../../style/components/popup';



</style>
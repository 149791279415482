<template>
  <div class="navside">
    <button
      class="navside--btn"
      aria-label="closes lateral menu"
      @click="closeSideNav"
    >
      <span class="navside--close">&nbsp;</span>
    </button>

    <nav class="navside--container">
      <router-link
        :to="{ name: 'Home' }"
        class="navside__header"
        aria-label="back to homepage"
        @click="closeSideNav"
      >
        <img
          src="@/assets/img/logo/hoe_logo_flat_white.svg"
          alt="logo flat hike on earth"
          width="381"
          height="48.26"
          class="navside__header--img"
        />
      </router-link>
      <router-link
        :to="{ name: item.page }"
        v-for="item in nav"
        :key="item.text"
        class="navside--item"
        @click="closeSideNav"
      >
        <Svg :className="'navside--svg'" :icon="item.svg"></Svg>
        {{ item.text }}
      </router-link>
    </nav>
    <div v-if="!$store.state.loggedIn" class="navside__login">
      <button
        class="btn btn--blue navside__login--item"
        @click="
          $store.commit('showSignupPopup');
          closeSideNav();
        "
      >
        {{ t("menu.signup") }}
      </button>
      <button
        class="btn btn--white navside__login--item"
        @click="
          $store.commit('showLoginPopup');
          closeSideNav();
        "
      >
        {{ t("menu.login") }}
      </button>
    </div>
    <div class="navside__lang">
      <div class="navside__lang--item">
        <button @click="setLanguage('en')">English</button>
      </div>
      <div class="navside__lang--item">
        <button @click="setLanguage('fr')">Français</button>
      </div>
    </div>
  </div>
</template>

<script>
import Svg from "../utils/Svg";
import { useI18n } from "vue-i18n";
import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import { changeLanguage } from "@/composables/languages.js";

import en from "@/locales/en/navigation.json";
import fr from "@/locales/fr/navigation.json";

export default {
  components: { Svg },
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();

    const { t } = useI18n({
      messages: {
        en: en,
        fr: fr,
      },
    });

    const setLanguage = (lang) => {
      changeLanguage(store, route, lang);
    };

    const nav = computed(() => [
      {
        text: t("menu.home"),
        svg: "home",
        page: "Home",
        display_type: [0, 1, 2, 9],
      },
      {
        text: t("menu.trails"),
        svg: "nav-trail",
        page: "Trails",
        display_type: [0, 1, 2, 9],
      },
      // {
      //     "text"      :  "Partner",
      //     "svg"       :  "nav-partner",
      //     "link"      :  "./partner/",
      // },

      // {
      //     "text"      :  "Hikerbox",
      //     "svg"       :  "nav-hikerbox",
      //     "link"      :  "./hikerbox/",
      // },
      {
        text: t("menu.community"),
        svg: "messenger",
        page: "Community",
      },
      {
        text: t("menu.contribute"),
        svg: "plus",
        page: "Contribute",
      },
      {
        text: t("menu.us"),
        svg: "nav-story",
        page: "About",
      },
    ]);

    const closeSideNav = () => {
      emit("closeSideNav");
    };

    return { setLanguage, closeSideNav, nav, t };
  },
};
</script>

<style lang="scss">
@import "@/style/main.scss";

.navside {
  height: 100vh;
  width: 30vw;
  // border-radius: 50%;

  position: fixed; //on ne veut pas que le bouton ne bouge
  top: 0rem;
  right: 0rem;
  background-image: linear-gradient(
    to right bottom,
    $color-orange-light,
    $color-orange-dark
  );
  //background: linear-gradient(to right, #136a8a, #267871); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  z-index: 10000; //works only if position defined

  animation: moveInRight 0.7s;

  transition: all 8s cubic-bezier(0.86, 0, 0.07, 1);

  @include respond(tab-land) {
    width: 50vw;
    &--hide {
      right: -50vw;
    }
  }
  @include respond(phone) {
    width: 100vw;
    &--hide {
      right: -100vw;
    }
  }

  &--container {
    position: relative;
    height: 100%;
    // padding: 12rem 0rem 12rem 0;
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    justify-content: center;

    @include respond(h-small) {
      padding: 10rem 0rem 6rem 0;
    }
  }

  &__login {
    position: absolute;
    top: 80%;
    left: 50%;
    border-radius: 3px;
    box-shadow: $shadow;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 1.4rem 2rem;
    background-color: rgba($color-white, 0.6);

    transform: translateX(-50%);

    &--item {
      &:not(:last-child) {
        margin-right: 2rem;
      }
    }
  }

  &__lang {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1.5em;
    display: flex;
    justify-content: center;
    color: $color-white;

    &--item {
      position: relative;
      font-size: 1.4rem;
      &::after {
        position: absolute;
        left: 100%;
        top: 0;
        width: 0.3rem;
        height: 100%;
        background-color: green;
      }
      &:not(:last-child) {
        margin-right: 2rem;

        &::after {
          content: "";
          position: absolute;
          left: 100%;
          top: 0;
          width: 0.2rem;
          height: 100%;
          margin-left: 1rem;
          background-color: currentColor;
        }
      }
      & button {
        @include buttonNoStyle;
        @include hoverUnderline;
      }
    }
  }

  &__header {
    color: $color-white;
    position: absolute;
    top: 8rem;
    left: 0;
    width: 100%;

    &--img {
      width: 100%;
      height: auto;
    }
    @include respond(h-medium) {
      display: none;
    }
  }

  &--svg {
    margin-right: 3rem;
    padding-left: 0rem;
    @include svg(4rem);
  }

  &--btn {
    position: absolute;
    top: 2.5rem;
    right: 2.5rem;
    border-radius: 50%;
    background-color: $color-white;
    z-index: 200;

    width: 6.5rem;
    height: 6.5rem;
    border: none;
    outline: none;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
  }

  &--close {
    position: relative;
    display: inline-block;
    width: 3rem;
    height: 2px;

    &::before,
    &::after {
      width: 3rem;
      height: 2px;
      background-color: $color-grey-75;
      display: inline-block;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      transition: all 0.2s;
    }

    &::before {
      top: -0.8rem;
      top: 0;
      transform: rotate(135deg);
    }
    &::after {
      top: 0.8rem;
      top: 0;
      transform: rotate(-135deg);
    }
  }

  &--item {
    position: relative;
    display: flex;
    align-items: center;
    color: $color-white;
    text-decoration: none;
    padding: 0.6rem 2rem;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    font-size: 1.8rem;
    font-weight: 400;
    &::after {
      opacity: 0;
      content: "";
      position: absolute;
      left: 50rem;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      transition: all 0.6s cubic-bezier(0.45, 1.84, 0.71, 0.7);
    }
    &:not(:last-child) {
      margin-bottom: 1.2rem;
    }
    &:hover {
      color: $color-orange-dark;
      &::after {
        background-color: $color-white;
        opacity: 1;
        left: 0;
      }
    }
    @include respond(tab-land) {
      font-size: 1.8rem;
      padding: 1rem 1.5rem;
    }
    @include respond(phone) {
      font-size: 1.8rem;
    }
    @include respond(h-medium) {
      padding: 0.3rem 1rem;
    }
    @include respond(h-small) {
      padding: 0;
    }
  }
}
</style>

export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Hike On Earth"])},
  "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DISCOVER THE STORY AND THE TEAM BEHIND HIKE ON EARTH!"])},
  "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover our story"])},
  "menu": {
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our goal"])},
    "how": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How it works ?"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The team"])},
    "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milestones"])}
  },
  "goal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our goal"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help hikers <strong>connect</strong> and find new <strong>hiking adventures</strong>"])},
    "story": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How it all started"])},
      "content": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In 2017, Hike On Earth founder Sébastien Goldberg set out to thru-hike the <a href='trail/pacific-crest-trail/'>Pacific Crest Trail</a>. This experience sparked his passion for hiking in the wilderness. The moment he was back home, Sébastien started looking for his next hiking adventure."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["But he struggled to find multi-day hikes and to convince friends or relatives to join in. In hindsight, he wished he could have teamed-up with other hikers to prepare and start new adventures. He soon found out that no website made that possible."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To address this, he founded Hike On Earth, a <span>collaborative trail database that helps hikers find and plan their next hiking adventure together</span>. Before long, Emeline and Ben brought their own ideas (and hiking shoes) into the project. Our small team was born!"])},
        
      ]
    }
  },
  "how": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How it works"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Find</strong>, <strong>plan</strong> and <strong>share</strong> your hikes with a single social platform"])},
    "data": [
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find new hikes around the world"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discovering new trails should not require long, strenuous hours of research. To help with that, Hike On Earth gathers long-distance trails from all over the world in one place. Hikes can be searched by country, duration and distance. Hikers, be prepared: you will find trails you have never heard of!"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan your hiking adventures"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An information toolkit is provided on each trail. It includes data on trail duration, difficulty, cost, useful links, and much more. All data is based on official sources or on information shared by hikers from the Hike On Earth community."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share your hikes with the community"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What a hiker learns on the trail can be gold to others. So anyone is welcome to add new trails, trail-specific information, tips and pictures into the platform. To ensure relevance, all data inputs are moderated by the Hike On Earth team."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find like-minded hiking partners"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiking or preparing an adventure alone is not always the safest or most fun option. On Hike On Earth, hikers can meet, help each other, and find hiking partners for their next trail. But that’s not all: they can also seek advice from trail veterans who have already completed a particular trail."])}
      }
    ],
    "gear": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A gear store to lighten up and support our platform"])},
      "content": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We believe that affordable, high-quality light gear should not be available only in the United States. So we designed light hiking gear now available to all EU residents. We field-tested all our products ourselves, <a href='store/'>come take a look!</a>"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As Hike On Earth is entirely free and independent, our store is vital to ensure the platform’s sustainability. We encourage regular users to support us by visiting our store or <a href='store/'>making a donation</a>."])},
        
      ]
    }
  },
  "team": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the team"])},
    "people": [
      {
        "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Founder"])},
        "descr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Be who you are, do what you love</b><br> This quote is driving his life for these last few years. After graduating as electromechanical engineer, Sebastien was looking for something meaningful to do, something he loves. Today, he wants to share his passion with a strong and passionate community of hikers."])}
      },
      {
        "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design"])},
        "descr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Young architect, Emeline uses her creative and artistic spirit to improve user experience. To her, the beauty of hiking starts as soon as a hiker browses the web and looks for his or her next adventure!"])}
      }
    ]
  },
  "timeline": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milestones"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview of the <strong>major steps</strong> in the development of our platform!"])},
    "steps": [
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First thru-hike"])},
        "descr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After graduating, Sébastien Goldberg gathers gear and money to thru-hike the PCT. He is amazed by this experience and inspired by the US’ light gear and strong hiking community."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What to do next ?"])},
        "descr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back in Europe, Sébastien struggles to find his next hikes and to connect with other trail enthusiasts. This inspires him to create an online platform on long-distance hiking."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development of the plateform"])},
        "descr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sébastien starts developing the platform from scratch. A great learning-by-doing adventure begins."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-launch campaign"])},
        "descr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 early contributors from the hiking community test the website and give their feedbacks."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hike on earth is online"])},
        "descr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After months of hard collective work and thousands of lines of code, Hike On Earth is launched."])}
      }
    ]
  }
}
<template>
  <div :id="'uploading-pic-'+id" class="upload__picture">
        <div class="upload__picture--container">
            <button class="upload__picture--delete" @click="deleteImage">
                <Svg className="upload__picture--delete-svg" icon="delete"/>
            </button>
            <img
                :src="image"
                alt=""
                class="upload__picture--img"
            />
            <div class="upload__picture--loader" :class="`upload__picture--loader-${100-loaded}`" :data-id="id"></div>
            <div class="upload__picture--loaded" :class="`upload__picture--loaded-${loaded}`" :data-id="id"></div>
        </div>
        <textarea
            name="picture-description"
            class="upload__picture--textarea"
            placeholder="Add description"
        ></textarea>
    </div>
</template>

<script>
import Svg from '../utils/Svg';

export default {
    components: {
        Svg
    },
    props:['image', 'loaded', 'id'],
    setup(props, {emit}){

        const deleteImage = () => {
            emit('delete', props.id)
        }
        return {
            deleteImage
        }
    },
    emits:['delete']
}
</script>

<style lang="scss">

@import '../../style/main';
.upload{
    &__picture{
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
        border: 1px solid $color-grey-75;
        position: relative;

        &--container{
            position: relative;
            height: 100%;
        }

        &--img{
            width: 100%;
            height: auto;
            display: block;
        }

        &--loader {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            background-color: rgba(0, 0, 0, .3);
            z-index: 100;

            @for $i from 0 through 100 {
                &-#{$i} {
                width: $i *1%;
                }
            }
        }
        &--loaded {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            border-bottom: 5px solid $color-blue-light;
            z-index: 100;

            @for $i from 0 through 100 {
                &-#{$i} {
                width: $i * 1%;
                }
            }
        }
        &--textarea {
            border: none;
            padding: 1rem;
            resize: none;
            outline: none;
            height: 10rem;
        }
        &--delete{
            @include buttonNoStyle;
            position: absolute;
            top: .7rem;
            right: .7rem;
            color:$color-white;
            z-index: 300;

            transition: all .2s;
            &:hover{
                transform: scale(1.1)
            }
            &-svg{
                @include svg(1.5rem);
            }
        }
    }
}

</style>
<template>
  <div class="popup">
        <button id="btn-popup-close" class="popup__close" @click="$store.commit('hidePopup')">
            <Svg className="popup__close--svg" icon="delete"/>
        </button>
        <div class="popup__content">
            <popup-upload-pictures v-if="data.name=== 'upload-pictures' || data.name === 'upload-static-image'" :name="data.name"></popup-upload-pictures>
            <popup-result v-else-if="data.name==='success' || data.name==='fail'"></popup-result>
            <popup-picture v-else-if="data.name==='picture'"></popup-picture>
            <popup-survey v-else-if="data.name==='survey'"></popup-survey>
            <popup-survey-response v-else-if="data.name==='surveyResponse'"></popup-survey-response>
            <popup-preview-post v-else-if="data.name==='preview-post'"></popup-preview-post>
            <popup-signup v-else-if="data.name==='signup'"></popup-signup>
            <popup-share-link v-else-if="data.name==='share-link'"></popup-share-link>
            <popup-trail-contribution-helper v-else-if="data.name==='trail-contribution-helper'"></popup-trail-contribution-helper>
            <!-- Fonctionne pour les delete popup, ... -->
            <popup-content v-else></popup-content> 
        </div>
    </div>
</template>

<script>
import { useStore } from 'vuex';
import Svg from '../utils/Svg';

import PopupContent from './PopupContent';
import PopupUploadPictures from './PopupUploadPictures';
import PopupSurveyResponse from './PopupSurveyResponse';
import PopupResult from './PopupResult';
import PopupPicture from './PopupPicture';
import PopupSurvey from './PopupSurvey';
import { ref, watch } from 'vue';
import PopupPreviewPost from './PopupPreviewPost.vue';
import PopupSignup from './PopupSignup.vue';
import PopupTrailContributionHelper from './PopupTrailContributionHelper.vue';
import PopupShareLink from './PopupShareLink.vue';

export default {
    components: {
        PopupUploadPictures,
        PopupPicture,
        PopupContent,
        PopupResult,
        PopupSignup,
        PopupSurvey,
        PopupSurveyResponse,
        Svg,
        PopupPreviewPost,
        PopupTrailContributionHelper,
        PopupShareLink
    },
    setup(){
        const store = useStore();
        const data = ref(store.state.popup.data); 
        watch(store.state, (newVal, oldVal) => {
            data.value = newVal.popup.data;
        })

        return {data}
    }
}
</script>

<style lang="scss">
@import '@/style/main';

.popup{
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(100, 100, 100, 0.05);

    z-index: 10000;
    opacity: 1;
    backface-visibility: hidden;

    transition: all 0.3s;

    display: flex;
    align-items: center;
    justify-content: center;

    @supports (-webkit-backdrop-filter: blur(10px)) or
        (backdrop-filter: blur(10px)) {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        background-color: rgba($color-black, 0.3);
    }
    &__content {
        position: relative;
        max-width: $page-max-width;
        margin: 3rem;
        width: 100%;
        background-color: $color-white;
        opacity: 1;
        
        display: flex;
        flex-direction: column;

        box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.5);
    }
    &__close {
        @include buttonNoStyle;
        position: absolute;
        top: 2rem;
        right: 2rem;

        color: $color-white;
        transition: all 0.2s;
        opacity: 0.5;

        display: flex;
        justify-content: center;
        align-items: center;

        z-index: 1000000;
        
        &:hover {
            opacity: 1;
            transform: scale(1.2);
        }

        &--svg {
            @include svg(2.2rem);
        }
    }
}

</style>
import axios from "axios";
import { createStore, Store } from "vuex";
import cookies from "vue-cookies";

export default createStore({
  state: {
    action: {
      display: false,
      color: "",
      message: "",
    },
    apiUrl: process.env.VUE_APP_API_BASEURL,
    baseUrl: "https://www.hikeonearth.com",
    gallery: [],
    language: "en",
    loggedIn: false,
    navDisplay: true,
    popup: {
      data: {},
      display: false,
      login: false,
      signup: false,
    },
    token: false,
    updateData: {},
    user: {},
    userNewsletter: {},
  },
  mutations: {
    //synchronous

    loggedIn(state, payload) {
      state.loggedIn = payload;
    },

    setUser(state, payload) {
      state.user = payload;
    },

    setCurrentUser(state, payload) {
      state.user = payload;
    },

    hideAuthentication(state) {
      state.popup.login = false;
      state.popup.signup = false;
    },
    hideLoginPopup(state) {
      state.popup.login = false;
    },
    showLoginPopup(state) {
      state.popup.login = true;
      state.popup.signup = false;
    },
    hideSignupPopup(state) {
      state.popup.signup = false;
    },
    showSignupPopup(state) {
      state.popup.login = false;
      state.popup.signup = true;
    },

    hidePopup(state) {
      state.popup.display = false;
    },
    showPopup(state, payload) {
      state.popup.display = true;
      state.popup.data = payload;
    },

    switchAuthentication(state) {
      state.popup.login = state.popup.signup;
      state.popup.signup = !state.popup.signup;
    },

    setActionMessage(state, { message, color }) {
      state.action.message = message;
      state.action.color = color;
    },
    showAction(state) {
      state.action.display = true;
    },
    hideAction(state) {
      state.action.display = false;
    },

    successPopup(state, payload) {
      state.popup.display = false;
      state.popup.data = payload;
      state.popup.display = true;
    },
  },
  actions: {
    // asynchronous

    async autoLogin(state) {
      if (state.user) {
      } else {
        if (cookies.get("jwt")) {
          //Set Bearer token for all further axios requests
          axios.defaults.headers["Authorization"] =
            "Bearer " + $cookies.get("jwt");

          try {
            const user = await axios.get("users/me");
            if (user.status === 200) {
              state.commit("setUser", user.data.data.data);
              state.commit("loggedIn", true);
            }
          } catch (err) {
            console.log(err.response);
            if (err.response.status === 401) {
              $cookies.set("jwt", "", 0);
            }
          }
        }
      }
    },

    async loggedInSuccess(state) {
      await state.dispatch("autoLogin");
      state.commit("hideAuthentication");
    },

    showAction(state, { message, color }) {
      state.commit("setActionMessage", { message, color });
      state.commit("showAction");
      setTimeout(() => {
        state.commit("hideAction");
      }, 5000);
    },
  },
  modules: {},
  getters: {
    getUser: (state) => state.user,
    getDisplay: (state) => {
      return "large";
    },
    getLoginPopup: (state) => state.popup.login,
    getPopupData: (state) => state.popup.display,
  },
});

<template>
  <div class="action" :class="`action--${color}`">
      <button class="action__close" @click="$store.commit('hideAction')">
          <Svg className="action__close--svg" icon="delete"/>
      </button>
      {{message}}
  </div>
</template>

<script>
import Svg from '../components/utils/Svg';
export default {
    components: {Svg},
    props:['message', 'color'],

}
</script>

<style lang="scss">

@import '../style/main';
.action{
    position: fixed;
    top: calc(#{$nav-height} + 2rem);
    right:0;

    z-index: 10000000;

    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: .1rem;
    text-transform: uppercase;
    padding: 2rem 6rem;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    animation: .4s moveInRight;
    
    
    &--red{
        color:$color-white;
        background-color:$color-red-light;
        border: 1px solid darken($color-red-light, 15);
    }
    &--green{
        color:$color-white;
        background-color:$color-green;
        border: 1px solid darken($color-green, 15);
    }

    &__close{
        display: none;
        @include buttonNoStyle;
        position: absolute;
        top: .4rem;
        left: .7rem;
        &--svg{
            @include svg(1.2rem)
        }
    }

    &:hover > &__close{
        display: block;
    }
    
}

</style>
<template>
    <div>
        <Authentication v-if="$store.state.popup.login" :formName="'login'"/>
        <Authentication v-if="$store.state.popup.signup" :formName="'signup'"/>
        <Action v-if="$store.state.action.display" :message="$store.state.action.message" :color="$store.state.action.color"/>
        <popup-container v-if="$store.state.popup.display"></popup-container>
        <popup-cookie-consent v-if="!$cookies.get('cookie-consent')"></popup-cookie-consent>
    </div>
  
</template>

<script>
import Action from './Action';
import Authentication from './Authentication';
import PopupContainer from './popup/PopupContainer';

import PopupCookieConsent from './popup/PopupCookieConsent';


export default {
    components:{
        Action, 
        Authentication, 
        PopupCookieConsent, 
        PopupContainer
    }
}
</script>
<template>
    <div class="index__header" :class="{'index__header--border': titleData.title}">
        <div v-if="titleData.title">
            <h2 class="index__header--title">
                {{titleData.title}}
            </h2>
            <ul v-if="lks.length" class="index__header--links">
                <li v-for="(l, i) in lks" :key="i">
                    <router-link :to="l.to" class="btn__text index__header--links-link"> 
                        <Svg v-if="l.svg" className="index__header--links-svg" :icon="l.svg"/>
                        <img v-if="l.img" :src="l.img" alt="">
                        {{l.name}}
                    </router-link>
                </li>
            </ul>
        </div>      
        <router-link v-if="titleData.action" :to="titleData.to ? titleData.to : { name: titleData.link}" class="index__header--action">
            <span class="u-phone-hide">{{titleData.action}}</span>
            <Svg className="index__header--action-svg" icon="plus"/>
        </router-link>
    </div>
  
</template>

<script>
import { ref, watch } from 'vue';
import Svg from '../utils/Svg';
export default {
    props:['titleData', 'links'],
    components:{Svg},
    setup(props){
        const lks = ref([]);

        if(props.links) lks.value = props.links;

        watch(() => props.links, () =>{
            lks.value = props.links;
        })
        return {lks}

    }
}
</script>

<style lang="scss" scoped>
@import '../../style/main';
.index{
    &__header{
        display: flex;
        padding: 2rem 0;
        margin-bottom: 2rem;

        &--title{
            font-size: 2.2rem;
            text-transform: uppercase;
            letter-spacing: .3rem;
        }

        &--links{
            display: flex;
            margin-top: 1rem;

            @include respond(phone){
                flex-direction: column;
                margin-top: 2rem;
            }
            &:empty{
                display: none;
            }
            & li{
                display: flex;
                list-style: none;
                // font-size: 1.6rem;
                letter-spacing: .1rem;
                
                &:not(:last-child){
                    margin-right: 1.2rem;
                    &::after{
                        content:"";
                        margin-left: 1.2rem;
                        width: 1px;
                        height: 100%;
                        background-color: currentColor;
                    }

                     @include respond(phone){
                            margin-right: 0;
                            margin-bottom: 1.5rem;
                        }
                }

                
            }
            &-link{
                display: flex;
                align-items: center;
            }

            & img{
                height: 1.4rem;
                width: auto;
                margin-right: 1rem;
            }

            &-svg{
                @include svg(1.8rem);
                margin-right: 1rem;
            }
        }

        &--border{
            border-bottom: 1px solid currentColor;
        }
        &--action{
            align-self: center;
            margin-left: auto;
            position: relative;
            display: flex;
            align-items: center;
            color:$color-blue-light;
            font-size: 1.6rem;
            text-decoration: none;
            font-weight: 400;
            letter-spacing: .1rem;
            &-svg{
                margin-left: 1.5rem;
                @include svg(2rem);

                @include respond(phone){
                    @include svg(2.4rem);
                    margin-left: 0;
                }
            }

            

            &::after{
                content:"";
                position: absolute;
                top: 110%;
                left: 0;

                width: 0;
                height: .2rem;
                background-color: currentColor;
                transition: all .2s ease-in-out;
            }


            &:hover{
                &::after{
                    width: 100%;
                }
            }
        }
        
    }
}

</style>
import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";
import Home from "../views/Home.vue";
import store from "../store/index";

import Loading from "@/components/utils/Loading";

const routes = [
  {
    path: "/admin/:page?",
    name: "Admin",
    meta: {
      title: "Admin",
    },
    props: (route) => ({ page: route.params.page || "users" }),
    component: () => import("../views/Admin.vue"),
  },
  {
    path: "/",
    name: "Home",
    meta: {
      title: "Find your next hiking adventure",
      metaTags: [
        {
          name: "description",
          content:
            "Hike on Earth is a web plateform that gathers a community of hikers looking for their next adventure!",
        },
        {
          name: "keywords",
          content:
            "trail, hiking, thru-hike, adventure, mountain, pictures, nature",
        },
        {
          property: "og:image",
          content: "https://www.hikeonearth.com/img/logo/linkPreview.jpg",
        },
      ],
    },
    component: Home,
  },
  {
    path: "/trails/:queryString?",
    name: "Trails",
    meta: {
      title: "Trails",
      metaTags: [
        {
          name: "description",
          content:
            "Discover the best long distance hiking trails around the world that matches perfectly with your criteria",
        },
        {
          property: "og:description",
          content:
            "Discover the best long distance hiking trails around the world that matches perfectly with your criteria",
        },
      ],
    },
    component: () => import("../views/Trails.vue"),
  },
  {
    path: "/moderation/:page?",
    name: "Moderation",
    meta: {
      title: "Moderation",
    },
    props: (route) => ({ page: route.params.page || "trails" }),
    component: () => import("../views/Moderation.vue"),
  },
  {
    path: "/trail/:slug",
    name: "Trail",
    meta: {
      title: "trailName",
      metaTags: [
        {
          name: "description",
          content: "",
        },
        {
          property: "og:description",
          content: "",
        },
      ],
    },
    component: () => import("../views/Trail.vue"),
  },
  {
    path: "/community/",
    name: "Community",
    meta: {
      title: "Community",
    },
    component: () => import("../views/Community.vue"),
  },
  {
    path: "/book/",
    name: "Book",
    meta: {
      title: "Collaborative Book - Live your hiking dreams",
      metaTags: [
        {
          name: "description",
          content:
            "Participate in a collaborative book talking about long distance hiking to help novice hikers live their hiking dreams",
        },
        {
          property: "og:description",
          content:
            "Participate in a collaborative book talking about long distance hiking to help novice hikers live their hiking dreams",
        },
      ],
    },
    component: () => import("@/views/Book.vue"),
  },
  {
    path: "/log/",
    name: "Log",
    meta: {
      title: "Log",
    },
    component: () => import("../views/Log.vue"),
  },
  {
    path: "/edit/:collection/:id",
    name: "Edit",
    meta: {
      title: "Edit",
    },
    component: () => import("../views/Edit.vue"),
  },
  {
    path: "/post/:slug",
    name: "Post",
    meta: {
      title: "postTitle",
      metaTags: [
        {
          name: "description",
          content: "",
        },
      ],
    },
    component: () => import("../views/Post.vue"),
  },
  {
    path: "/me/:page?",
    name: "Me",
    props: (route) => ({ page: route.params.page || "profile" }),
    meta: {
      title: "My profile",
    },
    component: () => import("../views/Me.vue"),
  },
  {
    path: "/contribute",
    name: "Contribute",
    component: () => import("../views/Contribute.vue"),
    meta: {
      title: "Contribute",
    },
  },
  {
    path: "/contribution",
    component: () => import("../views/Contribution.vue"),
    children: [
      {
        name: "Contribution",
        path: "",
        component: () => import("../components/contrib/ContribAddTrail"),
        meta: {
          title: "Contribute trails",
          name: "addtrail",
        },
      },
      {
        path: "addtrail",
        name: "ContributionTrail",
        component: () =>
          import(
            /* webpackChunkName: "contribution-add-trail" */ "../components/contrib/ContribAddTrail"
          ),
        loading: Loading,
        meta: {
          title: "Contribute trails",
          name: "addtrail",
        },
      },
      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/:id/posts is matched
        path: "newpost",
        name: "ContributionPost",
        component: () => import("../components/contrib/ContribNewPost"),
        meta: {
          title: "Contribute posts",
          name: "newpost",
        },
      },
    ],
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
    meta: {
      title: "About",
      metaTags: [
        {
          name: "description",
          content: "The about page of our example app.",
        },
        {
          property: "og:description",
          content: "The about page of our example app.",
        },
      ],
    },
  },
  {
    path: "/passwordforgot",
    name: "PasswordForgot",
    meta: {
      title: "Password Forgotten",
    },
    component: () => import("../views/PasswordForgotten.vue"),
  },
  {
    path: "/terms",
    name: "Terms",
    meta: {
      title: "Terms and conditions",
    },
    component: () => import("../views/Terms.vue"),
  },
  {
    path: "/privacy",
    name: "Privacy",
    meta: {
      title: "Privacy",
    },
    component: () => import("../views/Privacy.vue"),
  },
  {
    path: "/user/:slug",
    name: "User",
    props: (route) => ({ slug: route.params.slug }),
    meta: {
      title: "User",
    },
    component: () => import("../views/User.vue"),
  },
  {
    path: "/feedback/",
    name: "Feedback",
    meta: {
      title: "Your opinion matters",
    },
    component: () => import("../views/Feedback.vue"),
  },
  {
    path: "/email/:idUser/:token",
    name: "Email",
    meth: {
      title: "Email Settings",
    },
    component: () => import("../views/Email.vue"),
  },
  {
    path: "/verification/:idUser/:token",
    name: "Verification",
    meth: {
      title: "Email Verification",
    },
    component: () => import("../views/Verification.vue"),
  },
  {
    path: "/resetpassword/:token",
    name: "ResetPassword",
    meth: {
      title: "Password reset",
    },
    component: () => import("../views/ResetPassword.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import("../views/NotFound.vue"),
  },
];

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
  history: createWebHistory(),
  routes,
});

// GERE PAGE TITLE
router.beforeEach(async (to, from, next) => {
  //DISPLAY NAV TO PAGE
  store.state.navDisplay = true;

  // PROTECTED PAGES
  const protectedPages = [
    {
      name: "Admin",
      restrict: ["admin"],
    },
    {
      name: "Me",
      restrict: ["admin", "user"],
    },
  ];

  if (protectedPages.map((el) => el.name).includes(to.name)) {
    await store.dispatch("autoLogin");

    if (store.state.loggedIn) {
      if (
        !protectedPages
          .filter((el) => el.name === to.name)[0]
          .restrict.includes(store.state.user.role)
      ) {
        next({ name: "Home" });
      } else {
        console.log("ok");
      }
    } else {
      console.log("not connected");
      next({ name: "Home" });
    }
  }

  //We keep locale between pages
  if (from.query && from.query.locale && to.query && !to.query.locale) {
    next({ path: to.path, query: { locale: from.query.locale } });
    return;
  }

  // SET LANGUAGE
  if (to.query && to.query.locale && to.query.locale !== from.query.locale) {
    console.log(to.query.locale);
    store.state.language = to.query.locale;
  }

  // let documentTitle = `${process.env.VUE_APP_TITLE} - ${to.meta.title ||
  //   to.name}`;
  // if (to.params.title) {
  //   documentTitle += ` - ${to.params.title}`;
  // }
  // document.title = documentTitle;

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle)
    document.title = `${process.env.VUE_APP_TITLE} - ${nearestWithTitle.meta.title}`;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map((el) => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  // ROUTE PROTECTION

  next();
});

export default router;

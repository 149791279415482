<template>
    <div>
        <label v-if="inputdata.label" class="form__label">
            {{inputdata.label}}
            <helper-input v-if="inputdata.helper" :message="inputdata.helper"></helper-input>
        </label>
        <span class="form__error">{{inputdata.error}}</span>
        <input :type="inputdata.input || 'text'" :placeholder="inputdata.placeholder" class="form__input" v-model="inputs" :disabled="inputdata.disabled" :style="`color: ${inputdata.color ? inputdata.color: 'inherit'}`">
    </div>
</template>

<script>
import{ ref, computed} from 'vue';
import HelperInput from './HelperInput';
export default {
    components: {HelperInput},
    props:['inputdata', 'modelValue'],
    setup (props, { emit }) {
        const inputs = computed({ 
            get: () => props.modelValue, 
            set: (value) => emit('updateForm', {field: `${props.inputdata.name}${props.inputdata.field ? '.'+props.inputdata.field: ''}`, value: value}) 
        }) 
        
        return {inputs}
    },
    emits: ['updateForm'],
}
</script>

<style lang="scss">
@import '@/style/main';
@import '@/style/components/form';
</style>
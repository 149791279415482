import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";

import router from "./router";
import store from "./store";
import i18n from "./i18n";
import VueCookies from "vue3-cookies";
import axios from "axios";
import responsive from "./js/responsive";

//Resize Html for responsive website
window.onload = responsive.setHtmlFontSize;
window.onresize = responsive.setHtmlFontSize;

// AXIOS
axios.defaults.baseURL = process.env.VUE_APP_API_BASEURL + "/v1/";
// axios.defaults.withCredentials = true;
axios.defaults.headers["Accept-Language"] = "en";
axios.defaults.headers["Authorization"] = "Bearer " + $cookies.get("jwt");

createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(VueCookies)
  .mount("#app");

import moment from "moment";

export const computePeriod = (weatherData) => {
  const weather = { ...weatherData };

  let startMonth = "";
  let endMonth = "";

  const minGrade = 7;
  let deleteMonth = false;

  for (const [key, value] of Object.entries(weather)) {
    if (value < minGrade) {
      deleteMonth = true;
      delete weather[key];
    }
  }

  if (weather.dec && weather.jan && deleteMonth) {
    let set = true;
    for (let month of moment.monthsShort()) {
      if (!weather[month.toLowerCase()] && !endMonth) {
        endMonth = moment(month, "MMM")
          .subtract(1, "months")
          .format("MMMM");
        set = false;
      }

      if (set == false && weather[month.toLowerCase()]) {
        startMonth = moment(month, "MMM").format("MMMM");
        set = true;
      }
    }
  } else {
    startMonth = moment(Object.entries(weather)[0][0], "MMM").format("MMMM");
    endMonth = moment(
      Object.entries(weather)[Object.entries(weather).length - 1][0],
      "MMM"
    ).format("MMMM");
  }

  return [startMonth, endMonth];
};

<template>
    <div class="rating">
        <Svg v-for="i in maxRating*1" :key="i" className="rating__icon" :icon="Math.floor((cote+1-i)*2) >0 ? (Math.floor((cote+1-i)*2)%2 !== 0  && Math.floor((cote+1-i)*2) < 2? 'half-'+icon : icon) : `${icon}-empty`"/>
    </div>
</template>

<script>

import Svg from '../utils/Svg';
export default {
  components:{
    Svg
  },
  props:{
      'cote':{
          type: Number
      }, 
      'maxRating': {
          type: Number
      }, 
      "icon":{
          type:String
      }
      },

}
</script>

<style lang="scss" scoped>
@import '../../style/main';
.rating{
    display: flex;
    justify-content: center;
    &__icon{
        @include svg(1.6rem);
        &:not(:last-child) {
            margin-right: 0.3rem;
          }
        
    }
}

</style>
export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help us improve this platform !"])},
  "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We value your feedback for us."])},
  "descr": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are a small team of hikers and it is not possible to satisfy everybody by ourselves. Your feedback will help us to focus on the parts that matter."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You spotted a wrong information, a bug inside the website, you want us to develop a feature or have great ideas to share. This page is where you can share everything with us!"])},
    
  ],
  "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share your feedback"])},
  "feedbacktitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your feedback and ideas"])},
  "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public feedback"])},
  "needlogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to be logged in to share your feedback with us."])},
  "publictitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public feedbacks"])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback successfully submitted"])}
}
<template>
  <div>
      <div class="popup__header popup__header--blue popup__header--split">
          <h2 class="popup__header--title">
            {{data.title}}
          </h2>
          <h4 v-if="data.trail" class="popup__header--subtitle">
            {{data.trail}}
          </h4>
      </div>
      <div class="popup__main">
          <div class="popup__main--content">
            <div class="popup__text">
                <h2>{{data.topic}}</h2>
                <h3>{{data.topicDescription}}</h3>
            </div>
            <h5 class="popup__error">
                {{error}}
            </h5>
            <div class="popup__elem">
                <rating-form v-if="data.type==='rating'" :comments="data.comments" :maxInput="data.maxInput" :icon="data.iconRating.trim()" @updateForm="updateRating"></rating-form>
                <text-image-input-form v-else-if="data.type==='slider'" :icon="data.icon.trim()" :placeholder="data.placeholder" :onlyNumber="true" @updateForm="updateRating"></text-image-input-form>
            </div>
            <div class="popup__textarea">
                <label for="" class="popup__textarea--label">
                    Comment your vote (optionnal)
                </label>
                <textarea id="textarea-survey-comment" class="popup__textarea--input" name="comment-vote" placeholder="Write a comment to explain your vote" v-model="comment"></textarea>

            </div>
            <div class="popup__action">
                <button class="btn btn--blue-i popup__action--item" @click="$store.state.popup.display = false">
                    Cancel
                </button>
                <button class="btn popup__action--item" :class="submit? 'btn--blue' : 'btn--grey'" @click="actionPopup">
                    {{data.submit}}
                </button>
            </div>
          </div>
         
      </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';

import RatingForm from '../form/RatingForm';
import TextImageInputForm from '../form/TextImageInputForm'

import { textareaAutogrow } from '../../composables/form/textarea';

import axios from 'axios';


export default {
    components: {RatingForm, TextImageInputForm},
    setup(){
        const store = useStore();
        const data  = store.state.popup.data.data;
        const comment = ref(null);
        const error = ref(null);
        const submit = ref(false);
        let rating = 0;
        onMounted(()=> {
            textareaAutogrow('textarea-survey-comment');
        })

        const actionPopup= async () => {
            if(submit.value){
                try{    
                    const res = await axios({
                        method:'POST',
                        url:'surveys/response',
                        data:{
                            rating,
                            comment: comment.value,
                            trail: data.trailId,
                            survey: data.surveyId,
                        }
                    })
                    if(res.status == '201'){
                        //Success Popup
                         // ACTION EVERYTHING IS GOOD

                         //We change displayed data
                        store.state.updateData={
                            target:"trailSurveyDataVote",
                            surveyId: data.surveyId,
                            voteRating: rating
                        }
    
                        store.commit("successPopup", {
                            name:"success", 
                            title:"Thank you for your contribution !", 
                            message:"Answering our surveys helps other hiking chosing their next hiking adventure."
                        })
                    }
                }catch(err){
                    console.log(err.response)
                }
            }else{
                error.value='You need to add a rating before submitting'
            }
        }

        const updateRating= (value) => {
            error.value = '';
            submit.value = true;

            if(value.trim === '') submit.value = false
            rating = value;
        }

        return {actionPopup, comment, data, error,  submit, updateRating}
    }
}
</script>

<style lang="scss" scoped>
@import '../../style/main';
@import '../../style/components/popup';
</style>
<template>
    <div>
       <div class="popup__header">
          <router-link :to="{name:'User', params:{slug:'sebastien'}}" class="popup__user popup__header--btn" @click="closePopup">
                <img :src="`${$store.state.apiUrl}/images/profil/small/${dataPicture.user.profilePicture.split('.')[0]}.webp`" alt="" class="popup__user--img">
                {{dataPicture.user.name}}
          </router-link>
          <router-link :to="{name:'Trail', params:{slug:dataPicture.trail.slug}}" class="popup__trail popup__header--btn"  @click="closePopup">
                  {{dataPicture.trail.name}}
          </router-link>
          <button class="popup__like popup__header--btn" :class="{'popup__like--liked': dataPicture.userLike}" @click="likePic(dataPicture._id)">
              {{dataPicture.countLikes}}
              <Svg className="popup__like--svg" icon="like"/>
          </button>
      </div>
      <div class="popup__img">
          <button data-direction="left" class="popup__nav popup__nav--left" @click="navPictures('prev')">
            <Svg className="popup__nav--svg" icon="arrow-left"/>
          </button>
          <div class="popup__img--container">
              <img class="popup__img--src" :src="`${$store.state.apiUrl}/images/pictures/large/${dataPicture.path.split('.')[0]}.webp`" alt="">
          </div>
          <button data-direction="right" class="popup__nav popup__nav--right" @click="navPictures('next')">
            <Svg className="popup__nav--svg" icon="arrow-right"/>
          </button>
      </div>
      <div class="popup__footer"></div>
    </div>
</template>

<script>
import { useStore } from 'vuex';
import Svg from '../utils/Svg';
import {likePicture} from '../../composables/pictures/interaction';
import { ref } from 'vue';

export default {
    components:{Svg},
    setup(){
        const store = useStore();

        const dataPicture  = ref(store.state.popup.data.data);



        const likePic = async(id) => {
            const res  = await likePicture(store, id, dataPicture.value.userLike);
            //Like
            dataPicture.value.userLike = res.data.like;
            
            //Like counter
            if(dataPicture.value.userLike) {dataPicture.value.countLikes ++}
            else{dataPicture.value.countLikes --}
        }

        const navPictures = (direction) => {
            const currentPos =  store.state.gallery.map(function(e) { return e._id.toString(); }).indexOf(dataPicture.value._id);

            const maxPos =  store.state.gallery.length -1;
            let increment = 1;
            if(direction === 'prev') increment = -1;

            let newPos =currentPos + increment

            if(newPos > maxPos) newPos = 0;
            if(newPos< 0) newPos = maxPos;

            dataPicture.value = store.state.gallery[newPos];
        }

        const closePopup = () => {
            store.state.popup.display = false;
        }

        return{ closePopup, dataPicture, likePic, navPictures }
    }

}
</script>

<style lang="scss" scoped>
@import '../../style/main';

.popup{
    &__header{
        position: relative;
        width: 100%;
        padding: 2rem;
        height: 7rem;

        display: flex;
        align-items: center;
        border-bottom: 2px solid $color-blue-light;
        box-shadow: $shadow;

        @include respond(phone) {
            flex-direction: column;
            justify-content: center;
            height: auto;
            margin-top: 1.5rem;
        }

        &--btn{
            @include buttonNoStyle;

            display: flex;
            align-items: center;
            justify-content: flex-start;

            height: 4rem;
            padding: 0.6rem 1.8rem;
            border-radius: 5px;
            border: 1px solid $color-grey-75;

            box-shadow: $shadow-light;

            //FONT
            text-decoration: none;
            text-transform: uppercase;
            font-size: 1.4rem;
            font-weight: 700;
            letter-spacing: 0.1rem;
            color: $color-grey-75;

            transition: all 0.2s;
            opacity: 0.6;

            &:hover {
                opacity: 1;
            }
        }
    }

    &__img{
        position: relative;
        height: 70vh;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3rem 0rem;

        &--container{
            padding: 0 2rem;
            width: 100%;
            height: 100%;
        }

        &--src{
            height: 100%; 
            width: 100%; 
            object-fit: contain
        }

        @include respond(phone){
            padding: 5rem 0rem;
            
        }
    }

    &__user{
        margin-right: auto;

        &:active,
        &:focus {
            transform: translateY(-2px);
        }

        @include respond(phone) {
            justify-content: center;
            margin: 0 0 1rem 0;
            width: 90%;
        }

        &--img {
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 50%;
            margin-right: 1.5rem;
        }

    }

    &__trail{
        margin-right: 1rem;

        @include respond(phone) {
            margin: 0;
            width: 90%;
        }
    }

    &__like{
        cursor: pointer;

        @include respond(phone) {
            margin: 1rem 0 0 0;
        }
        &--liked{
            color: $color-blue-light !important;
            // &:hover {
            //     color: $color-blue !important;
            // }
        }
        &--svg{
            margin-left: 1rem;
            @include svg(1.8rem)
        }
    }

    &__nav{
        @include buttonNoStyle;
        transition: all 0.2s;
        color: $color-grey-75;
        opacity: .5;

        width: 7rem;
        height: 7rem;

        display: flex;
        justify-content: center;
        align-items: center;

        &--svg {
            @include svg(4rem);
        }
        &:hover {
            transform: scale(1.1);
            opacity: 1;
        }

        @include respond(phone){
            position: absolute;
            background-color: rgba($color-black, .2);
            border-radius: 5px;

            width: 4rem;
            height: 4rem;

            opacity: 1;

                 &--svg {
                @include svg(2rem);
            }
            
        }

        &--left{
            bottom: 0%;
            left: 1rem;

        }

        &--right{
            bottom: 0%;
            right: 1rem;
        }
        
    }

    &__footer{
        display: flex;
        width: 100%;
        padding: 1rem 2rem;

        &:empty{
            height: 3rem;
        }
    }
}
</style>
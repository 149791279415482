export default {
  "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PROJECT BORN IN BELGIUM"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discovering new trails should not require long, strenuous hours of research. To help with that, Hike On Earth gathers long-distance trails from all over the world in one place. Hikes can be searched by country, duration and distance. Hikers, be prepared: you will find trails you have never heard of!"])},
  "auth": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log In"])},
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Profile"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log Out"])}
  },
  "legal": {
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])}
  },
  "menu": {
    "heading": {
      "trails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trails"])},
      "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["community"])},
      "us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])}
    },
    "links": {
      "findtrail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["find a trail"])},
      "topeurope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top trails in Europe"])},
      "newtrails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Trails"])},
      "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trail Gallery"])},
      "sharetrail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share a trail"])},
      "popularpost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popular topics"])},
      "latestpost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest topics"])},
      "foodpost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talks about food"])},
      "gearpost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talks about gear"])},
      "ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ask a question"])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our story"])},
      "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give your feedback"])}
    }
  }
}
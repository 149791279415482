import axios from "axios";

export const hikeTrail = async (store, idTrail, isHiked) => {
  const loggedIn = store.state.loggedIn;
  if (loggedIn) {
    try {
      let method = "POST";
      if (!isHiked) {
        method = "DELETE";
      }

      const res = await axios({
        method,
        url: "trails/hike/" + idTrail,
      });
      return res;
    } catch (err) {
      console.log(err.response);
    }
  } else {
    store.commit("showSignupPopup");
  }
};

export const likeTrail = async (store, idTrail, isLiked) => {
  const loggedIn = store.state.loggedIn;

  if (loggedIn) {
    try {
      let method = "POST";
      if (isLiked) {
        method = "DELETE";
      }
      const res = await axios({
        method,
        url: "trails/like/" + idTrail,
      });
      return res;
    } catch (err) {
      console.log(err.response);
    }
  } else {
    store.commit("showSignupPopup");
  }
};

export const saveTrail = async (store, idTrail, isSaved) => {
  const loggedIn = store.state.loggedIn;

  if (loggedIn) {
    try {
      let method = "POST";
      if (isSaved) {
        method = "DELETE";
      }
      const res = await axios({
        method,
        url: "trails/save/" + idTrail,
      });
      return res;
    } catch (err) {
      console.log(err.response);
    }
  } else {
    store.commit("showSignupPopup");
  }
};

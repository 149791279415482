export default {
  "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projet né en Belgique"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrir de nouveaux chemins de randonnée ne devrait pas être compliqué. Afin de vous aider, Hike On Earth regroupe des randonnées longue distance dans les quatre coins du monde. Ces trails peuvent être filtrés par pays, distance et durée. Cher randonneur, tiens toi prêt, tu risques de d'allonger considérablement ta wish list."])},
  "auth": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon profil"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])}
  },
  "legal": {
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termes généraux"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vie privée"])}
  },
  "menu": {
    "heading": {
      "trails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trails"])},
      "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["community"])},
      "us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])}
    },
    "links": {
      "findtrail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouve un trail"])},
      "topeurope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meilleur trail en Europe"])},
      "newtrails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveaux Trails"])},
      "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galerie photos"])},
      "sharetrail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partage un trail"])},
      "popularpost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sujets populaires"])},
      "latestpost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernières discussions"])},
      "foodpost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parlons nourriture"])},
      "gearpost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parlons matériel"])},
      "ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poser une question"])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre histoire"])},
      "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donne ton feedback"])}
    }
  }
}
<template>
  <div class="form__input--img">
      <label class="form__input--label">
          <Svg className="form__input--svg" :icon="icon"/>
      </label>
      <input class="form__input form__input--field" type="text" v-model="inputs" :placeholder="placeholder" @keypress="onlyNumber?isNumber($event): false">
  </div>
</template>

<script>
import { computed } from 'vue'
import Svg from '../utils/Svg'
export default {
  props:['icon', 'placeholder', "onlyNumber"],
  components: {Svg},
  setup(props, { emit }){
    const inputs = computed({ 
            get:()=>"",
            set: (value) => emit('updateForm',value) 
        });
    const isNumber = (e) =>{
      let keyCode = (e.keyCode ? e.keyCode : e.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
          e.preventDefault();
      }
    }
        
    return {inputs, isNumber}
  },
  emits: ['updateForm'],
}
</script>

<style lang="scss" scoped>
@import '../../style/main';

$square-dim: 4.2rem;

.form{
  &__input{
    &--img{
      width: 100%;
      margin: 0rem 0 4rem 0;
      display: flex;
      box-shadow: $shadow-light;
    }

    &--svg{
      @include svg(2rem);
    }

    &--label{
      background-color: $color-blue-light;
      color:$color-white;
      height: $square-dim;
      width: $square-dim;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--field{
      margin-top: 0;
      height: $square-dim;
      width: 100%;
      
    }
  }
}

</style>
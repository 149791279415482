export default {
  "error": {
    "acceptConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must accept our general conditions before signing up"])},
    "passwordIdentical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your passwords are not identical"])},
    "passwordLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password must be at least 8 characters"])}
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouvez et partagez votre prochaine aventure à pied!"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas encore membre ? → <span class='u-color-blue-light'>Inscrivez-vous</span>"])}
  },
  "signup": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join our thru-hiking community"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
    "switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already member of the community ? →  <span class='u-color-blue-light'>Log in</span> here"])}
  },
  "form": {
    "email": {
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre email"])}
    },
    "password": {
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe"])}
    }
  },
  "passwordForgotten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié"])},
  "success": {
    "loggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are logged in !"])},
    "signUp": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You are signed up. An email has been sent to ", _interpolate(_named("email")), "."])}
  },
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contactez-nous"])}
}
import axios from "axios";

export const likePicture = async (store, id, isLiked) => {
  const loggedIn = store.state.loggedIn;

  if (loggedIn) {
    try {
      let method = "POST";
      if (isLiked) {
        method = "DELETE";
      }
      const res = await axios({
        method,
        url: "pictures/like/" + id,
      });
      return res;
    } catch (err) {
      console.log(err.response);
    }
  } else {
    store.commit("showSignupPopup");
    return false;
  }
};

export default {
  "popup": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer ce commentaire"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etes-vous certain de vouloir supprimer ce commentaire ?"])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre message a été supprimé"])}
  },
  "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["répondre"])},
  "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cacher"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["supprimer"])}
}
import axios from "axios";
import { useStore } from "vuex";

const facebookAppId =
  process.env.VUE_APP_MODE === "development"
    ? process.env.VUE_APP_FACEBOOK_DEVELOPMENT
    : process.env.VUE_APP_FACEBOOK_PRODUCTION;

export const loadFacebookApi = async () => {
  window.fbAsyncInit = function() {
    FB.init({
      appId: facebookAppId,
      cookie: true,
      xfbml: true,
      version: "v9.0",
    });
  };

  (function(d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  })(document, "script", "facebook-jssdk");
};

<template>
  <div>
    <Header :data="headerData"></Header>
    <button
      class="index__question"
      @click="askQuestion"
      aria-label="ask a question"
    >
      <Svg icon="question" class="index__question--svg"></Svg>
    </button>
    <div id="index" class="container__main">
      <index-carousel></index-carousel>
      <index-book></index-book>
      <index-forum></index-forum>
      <index-why v-if="!$store.state.loggedIn" />
      <index-story />
      <newsletter />
      <index-gallery />
    </div>
  </div>
</template>

<script>
import { computed, defineAsyncComponent, ref } from "vue";
import Loading from "@/components/utils/Loading";
import Header from "@/components/Header";
import Svg from "@/components/utils/Svg";

import IndexCarousel from "@/components/index/IndexCarousel";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

const IndexForum = defineAsyncComponent({
  loader: () =>
    import("@/components/index/IndexForum" /* webpackChunkName: "forum" */),
  loadingComponent: Loading,
  suspensible: false,
});

const IndexWhy = defineAsyncComponent({
  loader: () =>
    import("@/components/index/IndexWhy" /* webpackChunkName: "indexwhy" */),
  loadingComponent: Loading,
});

const IndexStory = defineAsyncComponent({
  loader: () =>
    import(
      "@/components/index/IndexStory" /* webpackChunkName: "indexstory" */
    ),
  loadingComponent: Loading,
});

const IndexGallery = defineAsyncComponent({
  loader: () =>
    import(
      "@/components/index/IndexGallery" /* webpackChunkName: "indexgallery" */
    ),
  loadingComponent: Loading,
});

const IndexBook = defineAsyncComponent({
  loader: () =>
    import("@/components/index/IndexBook" /* webpackChunkName: "indexlog" */),
  loadingComponent: Loading,
});

const Newsletter = defineAsyncComponent({
  loader: () =>
    import("@/components/Newsletter" /* webpackChunkName: "newsletter" */),
  loadingComponent: Loading,
});

import en from "@/locales/en/index.json"
import fr from "@/locales/fr/index.json"

export default {
  components: {
    IndexCarousel,
    IndexForum,
    IndexBook,
    IndexGallery,
    Header,
    Newsletter,
    IndexStory,
    IndexWhy,
    Svg,
  },

  setup() {
    const { t } = useI18n({
      messages: {
        en: en,
        fr: fr,
      },
    });
    const store = useStore();
    const router = useRouter();
    const headerData = computed(() => {
      return {
        background: "bck.jpg",
        title: t("title"),
        subtitle: t("subtitle"),
        layout: "home",
        scroll: "index",
        actions: [
          {
            title: t("cta"),
            svg: "search",
            link: "Trails",
            color: "blue",
          },
        ],
      };
    });

    const askQuestion = () => {
      if (store.state.loggedIn) {
        router.push({
          name: "ContributionPost",
        });
      } else {
        store.state.popup.display = true;
        store.state.popup.data = {
          name: "signup",
        };
      }
    };

    return {
      askQuestion,
      headerData,
    };
  },
};
</script>

<style lang="scss">
@import "@/style/components/slick";

@import "@/style/main";

.index {
  &__section {
    margin: 6rem 0;

    &--title {
      margin-right: auto;
      font-size: 2.2rem;
      text-transform: uppercase;
      letter-spacing: 0.3rem;
      margin-bottom: 2rem;
    }
  }

  &__question {
    @include buttonNoStyle;
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    background-color: $color-blue-light;
    width: 7rem;
    height: 7rem;
    opacity: 1;
    z-index: 300;

    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: all 0.2s;

    &:hover {
      transform: scale(1.1);
    }
    &--svg {
      color: $color-white;
      @include svg(4rem);
    }
  }

  &__action {
    position: fixed;
    z-index: 100;

    bottom: 3rem;
    right: 3rem;
    width: 7rem;
    height: 7rem;

    background-color: $color-blue-light;
    border-radius: 150rem;

    box-shadow: $shadow-light;

    display: flex;
    align-items: center;
    justify-content: center;

    color: $color-white;
    border: none;
    outline: none;
    cursor: pointer;
    font: inherit;

    transition: all 0.2s;

    &:hover {
      background-color: darken($color-blue-light, 10);
      transform: scale(1.1) translateY(-0.2rem);
      box-shadow: $shadow;
    }

    &--svg {
      @include svg(4rem);
    }
  }

  &__cta {
    background-position: center;
    background-size: cover;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;

    &--partner {
      height: calc(100% - 1.6rem);
    }

    &--trail {
      height: calc(100% - 4rem);
    }

    &--article {
      height: calc(100% - 4rem);
    }

    &--more {
      @include buttonNoStyle;
      width: 10rem;
      height: 10rem;
      border-radius: 50%;
      background-color: $color-white;
      color: $color-blue-light;

      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: $shadow;

      transition: all 0.2s ease-in-out;

      &-svg {
        @include svg(5rem);
      }

      &:hover {
        transform: scale(1.08);
      }
    }
    &--button {
      @include buttonNoStyle;
      position: absolute;
      bottom: 0.8rem;
      right: 0.8rem;
      color: $color-blue-light;
      font-weight: 400;
      text-transform: uppercase;
      font-size: 1.3rem;
      letter-spacing: 0.1rem;
      text-decoration: none;

      display: flex;
      align-items: center;

      &::after {
        content: "";
        position: absolute;
        top: 110%;
        left: 0;

        width: 0;
        height: 0.1rem;
        background-color: currentColor;
        transition: all 0.2s ease-in-out;
      }

      &:hover {
        &::after {
          width: 100%;
        }
      }

      &-svg {
        @include svg(1.5rem);
        margin-left: 0.6rem;
      }
    }
  }

  &__partner {
    display: flex;
    flex-direction: column;
    background-color: $color-grey-03;
    box-shadow: $shadow;
    margin-bottom: 1.6rem;

    &--img {
      background-position: center;
      background-size: cover;
      width: 100%;
      padding-top: 100%;
      height: 0;
    }

    &--container {
      display: flex;
      flex-direction: column;
      padding: 1rem;
    }

    &--content {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--flag {
      height: 1.4rem;
      width: auto;
      margin-left: auto;
    }
    &--info {
      margin-right: 1.8rem;
    }
    &--name {
      font-size: 1.3rem;
      text-transform: uppercase;
      letter-spacing: 0.1rem;
      font-weight: 700;
    }
    &--age {
      font-size: 1.2rem;
      font-weight: 400;
    }

    &--action {
      display: flex;
      margin-top: 1rem;
    }
    &--btn {
      display: flex;
      align-items: center;
      margin-left: auto;
      color: $color-blue-light;
      font-size: 1.2rem;
      letter-spacing: 0.1rem;
      text-decoration: none;
      text-transform: uppercase;

      &:hover > &-svg {
        transform: translateX(0.3rem) rotate(180deg) scaleX(-1);
      }

      &-svg {
        transition: all 0.2s;
        margin-left: 1rem;
        @include svg(1.6rem);
      }
    }
  }
}
</style>

<template>
    <div class="rating__container">
        <div class="rating__text">
            {{comment}}
        </div>
            <div class="rating__content">
            <div class="rating" v-for="i in maxInput" :key="i">
                
                    <input type="checkbox" :checked="ratings>=i?true: false" class="rating__input" ref="ratingInput">
                    <label class="rating__label" :class="{'rating__blue': statusArray[i-1]}" @mouseover="hoverRatings(i)" @mouseleave="unhoverRatings()" @click="updateRatings(i)">
                        <Svg className="rating--svg" :icon="icon"/>
                    </label>
                </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';

import Svg from '../utils/Svg';
export default {
    
    components:{Svg},
    props:['comments', 'maxInput', 'icon'],
    setup(props, {emit}){
        const comment = ref(props.comments['default'])
        const ratings = ref(0);
        const statusArray = ref(new Array(props.maxInput).fill(false));

        const updateComment = (pos) => {
            let position = 'rate-'+pos
            if(!pos || pos === 0) position = 'default';
            comment.value = props.comments[position];
           
        }

        const updateRatings = (number) => {
            ratings.value = number;
            updateComment(number);
            emit('updateForm', number);
        }
        const hoverRatings = (number) => {
            statusArray.value = statusArray.value.map((el, i) => {
                i<number ? el = true: el = false
                return el
            });
            updateComment(number);
        }

        const unhoverRatings = () => {
            statusArray.value = new Array(props.maxInput).fill(false);
            updateComment(ratings.value);
        }

        return{updateRatings, comment, hoverRatings, ratings, statusArray, unhoverRatings}
    },
    emits:['updateForm']
}
</script>

<style lang="scss" scoped>
@import '../../style/main';
.rating {
    color: transparent;
    cursor: pointer;


    &:not(:last-child) {
        margin-right: 0.8rem;
    }
    &--svg {
        stroke: $color-blue-light;
        stroke-width: 0.8rem;

        @include svg(3rem);
    }
    &__container{
        margin: 2rem 0 5rem 0;
    }
    &__content {
        display: flex;
        justify-content: center;
        margin: 1rem 0 2rem 0;
    }
    &__text {
        width: 100%;
        text-align: center;
        font-size: 1.6rem;
        text-transform: uppercase;
        font-weight: 500;
    }
    &__input {
        display: none;
        cursor: pointer;
    }
    &__label {
        cursor: pointer;
        transition: all 0.2s;
        &:hover {
        color: $color-blue-light;
        }
    }
    &__blue {
        color: $color-blue-light;
    }
    &__transparent {
        color: transparent !important;
    }

    &__input:checked + &__label {
        color: $color-blue-light;
    }


}

</style>
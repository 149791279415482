export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMMUNITY"])},
  "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SHARE YOUR EXPERIENCE AND ASK YOUR QUESTION TO OTHER HIKERS AROUND THE GLOBE!"])},
  "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Community topics"])},
  "heading1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talks about hiking"])},
  "heading2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be part of this generous <span>hiking community</span> and <span>share</span> your journey!"])},
  "sort": {
    "latest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["latest"])},
    "popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["popular"])},
    "old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oldest"])}
  },
  "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new subject"])},
  "categories": {
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all"])},
    "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["book"])},
    "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["general"])},
    "food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["food"])}
  }
}
<template>
    <div class="card" @click="redirectToTrail(trailData.slug)">
        <div class="card__side">
            <div class="card__picture">
                <img :src="`${$store.state.apiUrl}/images/trails/cover/card/${trailData.coverImage.split('.')[0]}.webp`" :alt="`${trailData.name} background `" class="card__picture--image">
                <div class="card__picture--img">
                    <img class="card__picture--img-item" width="297" height="210" :src="`${$store.state.apiUrl}/images/trails/map/${trailData.coverMap}`" :alt="`${trailData.name} trail map from gpx `">
                </div>
            </div>
            <div class="card__actions">
                <div class="card__actions--likes">
                    <button 
                        :class="`card__actions--likes-btn card__actions--likes-btn-like mousehover mousehover--bottom ${trailLiked ? 'card__actions--likes-liked' : ''}`" 
                        data-hover="Like"
                        aria-label="like button"
                        @click.stop="like(trailData._id)"
                    >
                         <Svg className="card__actions--likes-svg" icon="like" />
                    </button>
                    <button 
                        :class="`card__actions--likes-btn card__actions--likes-btn-love mousehover mousehover--bottom ${trailSaved ? 'card__actions--likes-loved' : ''}`"
                        data-hover="Save this trail"
                        aria-label="save button"
                        @click.stop="save(trailData._id)"

                    >
                        <Svg className="card__actions--likes-svg" icon="love2" />
                    </button>
                    
                </div>
                <div v-if="displayMap" class="card__actions--position">
                  <Svg className="card__actions--position-svg" icon="localisation"/>
                  {{trailData.distanceFrom.toFixed(0)}} km
                </div>
                
            </div>
            <h3 class="card__title">
                {{trailData.locales && trailData.locales[$store.state.language] && trailData.locales[$store.state.language].name ? trailData.locales[$store.state.language].name:  trailData.name}}
            </h3>
            <div class="card__details">
                <ul>
                    <li class="card__list card__list--trail" >
                      <div v-for="flag in trailData.country" :key="flag" >
                        <img :src="`${$store.state.apiUrl}/images/flag/thumbnail/${flag}.webp`"  :alt="`flag ${flag}`" class="card__flag">
                      </div>
                        <span>{{trailData.coverLocation}}</span>
                    </li>
                    <li class="card__list card__list--trail">
                        <span>{{trailData.distance}}</span> km 
                        - 
                        <span>{{trailData.duration}}</span> {{t('units.days')}}
                    </li>
                    <li class="card__list card__list--trail">
                        <span>{{capitalizeFirstLetter(moment().month(trailData.startPeriod).locale($store.state.language).format('MMMM')) }}</span> 
                        to 
                        <span>{{capitalizeFirstLetter(moment().month(trailData.endPeriod).locale($store.state.language).format('MMMM'))}}</span> 
                    </li>

                </ul>
            </div>
            <div class="card__button">
                <router-link :to="{name:'Trail', params: { slug: trailData.slug, title:trailData.name}}" class="card__button--item">
                    <Svg className="card__button--item-svg" icon="arrow-right-2" />
                    {{t('cta')}}
                </router-link>
            </div>
        </div>
    </div>

  
</template>

<script>
import {ref} from 'vue';
import Svg from '../utils/Svg';
import moment from "moment";

import {capitalizeFirstLetter} from '@/composables/utils/capitalize.js';

import { useRouter } from 'vue-router';
import { useStore } from 'vuex';



import {computePeriod} from '@/composables/trails/period';
import {likeTrail, saveTrail} from '@/composables/trails/interaction';
import { useI18n } from 'vue-i18n';


import en from "@/locales/en/component/trailCard.json";
import fr from "@/locales/fr/component/trailCard.json";

export default {
    props:{
        data: Object,
        displayMap: Boolean
    },
    components:{
        Svg
    },
    setup(props){
      const {t} = useI18n({
        messages:{
              en: en,
              fr: fr
            }
      })
      const store = useStore();

      let trailLiked = ref(props.data.userLike);
      let trailSaved = ref(props.data.userSaved);

      const trailData = ref(props.data);

      const router = useRouter();


      //Compute period
      
      [trailData.value.startPeriod, trailData.value.endPeriod] = computePeriod(props.data.weather);
  



      const redirectToTrail = (slug) => {
        router.push({
          name: 'Trail',
          params: {
            slug
          },
        });
      };

      const save = async(idTrail) => {
        const res = await saveTrail(store, idTrail, trailSaved.value);
        trailSaved.value = res.data.save;
      };

      const like = async (idTrail) => {
        const res = await likeTrail(store, idTrail, trailLiked.value);
        trailLiked.value = res.data.like;
      }


      return{
        capitalizeFirstLetter,
        like,
        moment,
        redirectToTrail,
        save,
        t,
        trailData,
        trailLiked,
        trailSaved
      }
    },
}
</script>

<style lang="scss">

@import "@/style/main";
.card {
  position: relative;
  // width: 30rem;

  flex: 0 0 31%;
  margin-bottom: 4rem;

  @include respond(tab-port) {
    flex: 0 0 48%;
  }
  @include respond(small) {
    flex: 0 0 100%;
    margin: 0 auto;
    margin-bottom: 4rem;
  }

  &__side {
    position: relative;
    height: 56.5rem;

    width: 100%;
    border-radius: 3px;
    overflow: hidden; //l'image casse la bordure ronde, tout se qui depasse maintenant est cache

    box-shadow: 0 1.5rem 4rem rgba($color-black, 0.15);
    cursor: pointer;

    @include respond(phone) {
      height: 63rem;
    }
  }

  &__picture {
    position: relative;
    height: 23rem;
    width: 100%;

    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

    transition: 0.2s all;

    // @include respond(tab-port) {
    //   height: 50%;
    // }
    @include respond(phone) {
      height: 27rem;
    }

    &--image{
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    &--img {
      position: absolute;
      top: 0;
      left: 100%;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.8);

      display: flex;
      justify-content: center;
      align-items: center;

      transition: all 0.3s ease-in-out;

      &-item {
        width: 80%;
        height: 100%;
      }
    }
  }
  &:hover &__side > &__picture > &__picture--img {
    left: 0;
  }
  &__actions {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba($color-black, 0.15);
    padding: 0rem 1.5rem;

    @include respond(phone){
      padding: .4rem 1.6rem;
    }

    &--likes {
      padding: 1rem 1.2rem;
      &-btn {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        &:not(:last-child) {
          margin-right: 1.2rem;
        }
        &:hover {
          color: $color-white;
        }
      }

      &-liked {
        color: $color-blue-light;
      }
      &-loved {
        color: $color-red-light;
      }

      &-btn-like:hover > &-svg {
        fill: $color-blue-light;
      }
      &-btn-love:hover > &-svg {
        fill: $color-red-light;
      }
      &-svg {
        @include svg(2rem);
        @include respond(phone){
          @include svg(2.6rem);
        }
      }
      margin-right: auto;
    }
    &--position {
      z-index: 10;
      // padding: 0rem 1.2rem;
      font-size: 1.2rem;
      letter-spacing: 0.1rem;

      display: flex;
      &-svg {
        @include svg(1.6rem);
        margin-right: 1rem;
      }
    }
  }
  &__title {
    font-size: 2rem;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.1rem;

    color: $color-grey-70;

    width: 100%;
    text-align: center;
    padding: 1rem 2rem;
    height: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
    &__list {
        display: flex;
        align-items: center;
        justify-content: center;

        &--trail {
            text-align: center;
            font-size: 1.5rem;
            padding: 1rem;
        }

        span {
          margin-left: 0.4rem;
          margin-right: 0.4rem;
        }
  }
  &__flag{
    height: 1.6rem;
    width: auto;
    margin-right: .8rem;
  }
  &__button{
    
    margin-top: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &--item{
      display: flex;
      align-items: center;

      font-size: 1.4rem;
      color:inherit;
      text-decoration: none;
      border-radius: 3px;
      box-shadow: $shadow-light;

      padding: 1rem 2rem;
      border: 1px solid $color-blue-light;

      text-transform: uppercase;
      letter-spacing: .1rem;

      transition: all .2s;

      &:hover{
        background-color: $color-grey-05;
      }

      &:hover > &-svg{
        transform: translateX(.5rem) rotate(180deg) scaleX(-1);
      }

      &-svg{
        color:$color-blue-light;
        @include svg(1.6rem);
        margin-right: 1.4rem;

        transition: all .2s;
      }
    }
  }
}


</style>
export default {
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["back to forum"])},
  "card": {
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comment"])},
    "like": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["like"])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See Post"])},
    "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["posted in"])}
  },
  "posted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["posted in"])},
  "comment": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["comment"]), _normalize(["comment"]), _normalize(["comments"])])},
  "commentPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write a comment"])},
  "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["post comment"])},
  "reactions": {
    "endorse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["endorse"])},
    "love": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["love"])},
    "fun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fun"])},
    "wow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wow"])},
    "sad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sad"])}
  }
}
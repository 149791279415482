<template>
  <div>
    <div class="popup__header popup__header--blue">
          <h2 class="popup__header--title">
            How to add a new trail ?
          </h2>
      </div>
    <div class="popup__main">
      <div class="popup__main--content">
          <div class="tuto__item">
            <h3 class="tuto__title">
                How does it work ?
            </h3>
            <div class="tuto__content">
                <img :src="`${$store.state.apiUrl}/images/static/tutorial/medium/tuto_add_trail.webp`" alt="" class="tuto__img" />
            </div>
        </div>
        <div class="tuto__item">
            <h3 class="tuto__title">
                What information do I need to add a trail ?
            </h3>
            <div class="tuto__content">
                <div class="tuto__features">
                    <div v-for="item in features" :key="item.icon" class="tuto__features--item">
                        <Svg :icon="item.icon" className="tuto__features--svg"/>
                        <h4 class="tuto__features--text">
                            {{item.name}}
                        </h4>
                    </div>
                </div>
            </div>
        </div>
        <router-link :to="{name:'Contribution', params:{page:'addtrail'}}" class="btn btn--blue btn__spacing">
            Add a new trail
        </router-link>

      </div>

  </div>
</div>

</template>

<script>

import Svg from '../utils/Svg';
export default {
    components:{
        Svg
    },
    setup(){
        const features = [
            {
                name:"Trail name",
                icon: "trail-sign"
            },
            {
                name:"Distance",
                icon: "trail"
            },
            {
                name:"Duration",
                icon: "duration"
            },
            {
                name:"Best hiking period",
                icon: "period"
            },
            {
                name:"Trail region",
                icon: "region"
            },
            {
                name:"Country",
                icon: "world"
            },
            {
                name:"Picture",
                icon: "picture"
            },
            {
                name:"Short description",
                icon: "description"
            },
            {
                name:"Optionnal - GPX Tracks",
                icon: "gpx"
            }
        ];
        console.log(features);

        return {features}
    }
}
</script>

<style lang="scss" scoped>
@import '../../style/main';
@import '../../style/components/popup';
.tuto {
  margin: 4rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__item {
    &:not(:last-child) {
      margin-bottom: 4rem;
    }
  }
  &__title {
    color: $color-blue-light;
    margin-bottom: 1rem;

    text-transform: uppercase;
    letter-spacing: 0.2rem;
    font-size: 1.8rem;
  }
  &__content {
    width: 100%;
    background-color: $color-white;
    box-shadow: $shadow;
    padding: 4rem 2rem;
  }
  &__img {
    width: 100%;
  }

  &__features {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    &--item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 1.8rem;
      &:not(:last-child) {
        margin-right: 3rem;
      }
    }
    &--svg {
      color: $color-blue-light;
      @include svg(3.5rem);
      margin-bottom: 1rem;
    }
    &--text {
      text-align: center;
      font-size: 1.4rem;
      text-transform: uppercase;
      color: $color-grey-65;
      font-weight: 300;
    }
  }
}


</style>
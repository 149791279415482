export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communauté"])},
  "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partagez votre expérience et posez vos questions à d'autres randonneurs du globe!"])},
  "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sujets de la communauté"])},
  "heading1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discussions autour de la randonnée"])},
  "heading2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenez part à notre généreuse <span>communauté de randonneurs</span> et <span>partagez</span> vos rêves d'aventures!"])},
  "sort": {
    "latest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récent"])},
    "popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Populaire"])},
    "old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancien"])}
  },
  "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nouvelle discussion"])},
  "categories": {
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tout"])},
    "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["livre"])},
    "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["général"])},
    "food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nourriture"])}
  }
}
export default {
  "units": {
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jours"])}
  },
  "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrir ce trail"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
  "like": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["like"])},
  "hiked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai fini ce trail!"])},
  "notHiked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez fait ce trail"])},
  "hikedHover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez ici si vous avez fait ce trail."])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brève description"])}
}
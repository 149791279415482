<template>
    <a href="mailto:info@hikeonearth.com" class="contactForm__contact contactForm__contact--grey">
        {{text}}
        <Svg className="contactForm__contact--svg" icon="email"/>
    </a>
</template>

<script>
import Svg from '../utils/Svg';
export default {
    components: {
        Svg
    },
    props:['text'],

}
</script>

<style lang="scss">
@import '@/style/main';
.contactForm{
     &__contact{
        -webkit-font-smoothing: antialiased;
        position: absolute;
        bottom: 1.2rem;
        right: 1.2rem;

        display: flex;
        align-items: center;

        text-decoration: none;
        color: $color-white;

        font-size: 1.6rem;
        text-transform: uppercase;
        letter-spacing: 0.1rem;

        transition: all 0.2s;

        &::after {
            content: "";
            position: absolute;
            width: 0%;
            background-color: currentColor;
            top: calc(100% + .3rem);
            left: 0%;
            height: .1rem;
            transition: all 0.2s;
        }

        &:hover {
            &::after {
                width: 100%;
            }
        }

        @include respond(h-medium) {
            display: none;
        }

        &--grey {
            color: $color-grey-65;
        }

        &--svg {
            @include svg(2rem);
            margin-left: 1rem;
        }
    }
}

</style>
<template>
    <div class="popup__result">
        <div class="popup__result--content">
            <Svg :className="`popup__result--svg popup__result--svg-${classIconColor}`" :icon="icon"/>
            <h2>
                {{data.title}}
            </h2>
            <h4>
                {{data.message}}
            </h4>
        </div>
            <button class="btn btn--blue-i popup__result--close" @click="$store.state.popup.display=false">
                Close
            </button>
        
    </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';

import Svg from '../utils/Svg';
export default {
    components:{
        Svg
    },
    setup(){
        const store = useStore();
        const data = store.state.popup.data;

        const popup = data.name;
        let icon = 'validate';
        let classIconColor='green';

        //LOGO MANAGEMENT
        if(popup === 'fail'){
            icon="delete";
            classIconColor = 'red';
        }

        return {
            classIconColor,
            data,
            icon
        }
    }
}
</script>

<style lang="scss">
@import '../../style/main';
.popup{
    &__result {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 4rem;
        &--content {
            padding: 6rem 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
        }
        &--svg {
            @include svg(15rem);
            &-green {
                color: $color-green;
            }
            &-orange {
                color: $color-orange-dark;
            }
            &-red {
                color: $color-red;
            }
            &-red-light {
                color: $color-red-light;
            }
            &-blue {
                color: $color-blue-light;
            }
        }
        & h2 {
            margin-top: 4rem;
            margin-bottom: 0.5rem;
            font-weight: 700;
            font-size: 2.3rem;
            text-transform: uppercase;
            padding: 1rem;
            text-align: center;
        }
        & h4 {
            padding: 1rem;
            text-align: center;
            font-weight: 300;
            font-size: 1.6rem;
            letter-spacing: 0.1rem;
        }

        &--close{
            margin-top: 0rem;
        }
    }
}
 

</style>
<template>
        <div class="popup__subscribe--container">
            <div class="popup__subscribe">
            <div
                class="popup__subscribe--header"
                :style="`background-image: url(${$store.state.apiUrl}/images/static/background/small/trees.webp)`"
            >
                <img
                :src="`${$store.state.apiUrl}/images/static/logo/small/logo-color-text.webp`"
                alt=""
                class="popup__subscribe--header-logo"
                />
            </div>
            <div class="popup__subscribe--content">
                <div class="popup__subscribe--title">Subscribe</div>
                <h4 class="popup__subscribe--subtitle">
                Stay informed about the best hiking trails on earth
                </h4>
                <div class="popup__subscribe--features">
                <div class="popup__subscribe--features-item">
                    <div class="popup__subscribe--features-logo">
                        <Svg icon="trail2" className="popup__subscribe--features-svg"/>
                    </div>
                    Find new long-distance trails
                </div>
                <div class="popup__subscribe--features-item">
                    <div class="popup__subscribe--features-logo">
                        <Svg icon="partner2" className="popup__subscribe--features-svg"/>
                    </div>
                    Connect with other hikers
                </div>
                <div class="popup__subscribe--features-item">
                    <div class="popup__subscribe--features-logo">
                        <Svg icon="infos" className="popup__subscribe--features-svg"/>
                    </div>
                    Share hiking fun facts, tips and tricks
                </div>
                </div>
                <button class="popup__subscribe--signup" @click="openSignup">
                    <span class="popup__subscribe--signup-title"> Sign up </span>
                    Our platform is free and independant
                </button>
            </div>
            </div>
            <button class="popup__subscribe--not" @click="$store.commit('hidePopup')">
                I don't want to subscribe
            </button>
        </div>

</template>

<script>
import { useStore } from 'vuex';

import Svg from '../utils/Svg';

export default {
    components:{
        Svg
    },
    setup(){
        const store = useStore();
        const openSignup = () => {
            store.commit('hidePopup')
            store.commit('showSignupPopup');
        }
        return {
            openSignup
        }
    }

}
</script>

<style lang="scss">

@import '../../style/main';
.popup{
    &__subscribe {
        background-color: $color-white;
        border-radius: 5px;
        overflow: hidden;
        width: 100%;
        &--container {
        max-width: 65rem;
        @include absCenter;

        display: flex;
        flex-direction: column;
        align-items: center;

        @include respond(phone) {
            width: 80%;
        }
        }

        &--header {
        background-size: cover;
        background-position: center;
        height: 25rem;
        position: relative;
        margin-bottom: 2rem;
        &-logo {
            top: 100%;
            left: 50%;
            position: absolute;
            width: 10rem;
            height: auto;

            transform: translate(-50%, -85%);

            box-shadow: $shadow;
        }
        }
        &--content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem 4rem;
        }

        &--title {
        font-weight: 900;
        letter-spacing: 0.3rem;
        font-size: 2.2rem;
        text-transform: uppercase;
        text-align: center;
        }
        &--subtitle {
        font-weight: 400;
        letter-spacing: 0.1rem;
        font-size: 1.4rem;
        margin-bottom: 3rem;
        text-align: center;
        }

        &--features {
        display: flex;
        margin-bottom: 3rem;

        @include respond(phone) {
            flex-direction: column;
        }

        &-item {
            position: relative;
            border: 1px solid $color-blue-light;
            padding: 1rem;
            font-weight: 700;
            font-size: 1.8rem;
            text-transform: uppercase;
            width: 15rem;
            &:not(:last-child) {
            margin-right: 1.5rem;

            @include respond(phone) {
                margin-bottom: 1.5rem;
                margin-right: 0;
            }
            }

            @include respond(phone) {
            padding: 1rem 2rem;
            font-size: 1.4rem;
            font-weight: 400;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            }
        }
        &-logo {
            position: absolute;
            bottom: 0rem;
            right: 0.5rem;

            @include respond(phone) {
            display: none;
            }
        }
        &-svg {
            color: $color-blue-light;
            @include svg(2.6rem);
        }
        }

        &--signup {
        background-color: $color-blue-light;
        color: $color-white;
        font: inherit;
        border: none;
        box-shadow: $shadow;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem 3rem;
        font-weight: 300;

        font-size: 1.2rem;
        letter-spacing: 0.1rem;
        border-radius: 5px;
        cursor: pointer;
        outline: none;

        transition: all 0.2s;

        &:hover {
            transform: scale(1.1);
        }
        &-title {
            font-weight: 700;
            letter-spacing: 0.3rem;
            font-size: 1.8rem;
            text-transform: uppercase;
            margin-bottom: 0.3rem;
        }
        }
        &--not {
            background-color: transparent;
            border: none;
            font: inherit;
            outline: none;
            color: $color-white;
            text-transform: uppercase;
            letter-spacing: 0.1rem;
            text-align: center;
            margin-top: 3rem;
            font-weight: 400;
            font-size: 1.4rem;
            cursor: pointer;

            position: relative;

            &::after {
                position: absolute;
                content: "";
                width: 0%;
                height: 0.1rem;
                background-color: currentColor;

                top: 100%;
                left: 0;
                transition: all 0.4s ease-out;
            }
            &:hover {
                &::after {
                width: 100%;
                }
            }
        }
    }
}

</style>
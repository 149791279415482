export default {
  "menu": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["home"])},
    "trails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trails"])},
    "contribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contribute"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["community"])},
    "us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["about us"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["log in"])},
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sign up"])}
  }
}
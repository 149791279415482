<template>
    <div>
        <div class="popup__header popup__header--blue popup__header--split">
            <h2 class="popup__header--title">
                {{data.title}}
            </h2>
            <h3 class="popup__header--subtitle">
                {{data.trailName}}
            </h3>
        </div>
        <div class="popup__main">
            <div class="popup__main--content">
                <h4 class="popup__main--title">
                    Share the best ressource linked to <span>{{data.trailName}}</span>
                </h4>
                <div class="popup__error">
                    {{error}}
                </div>
                <div class="popup__form">
                    <div class="form__item">
                        <select-input :inputdata="formInputs.category" @updateForm="handleForm"></select-input>
                    </div>
                    <div class="form__item">
                        <text-input :inputdata="formInputs.name" @updateForm="handleForm"></text-input>
                    </div>
                    <div class="form__item">
                        <text-input :inputdata="formInputs.link" @updateForm="handleForm"></text-input>
                    </div>
                </div>
            </div>
            <div class="popup__action">
                <button class="btn btn--blue-i popup__action--item" @click="$store.state.popup.display = false">
                    Cancel
                </button>
                <button class="btn popup__action--item" :class="submitReady? 'btn--blue' : 'btn--grey'" @click="submitLink">
                    {{data.submit}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>

import SelectInput from '../form/SelectInput';
import TextInput from '../form/TextInput';

import {isValidUrl} from '../../composables/utils/validUrl';

import { ref } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';
export default {
    components:{
        SelectInput,
        TextInput
    },
    setup(){
        const store = useStore();
        const data  = store.state.popup.data;
        const submitReady = ref(false);
        const error = ref(null);

        const formValue = ref({});

        const handleSubmitReady = () => {
            const requiredFields = ['name', 'link', 'type'];
            let ready  = true;
            for (let req of requiredFields){
                if(!formValue.value[req]){
                    ready = false;
                }
            }
            submitReady.value = ready;
        }

        const submitLink = async () => {
            error.value='';
            if(submitReady.value){
                formValue.value['trail'] = data.idTrail;
                if(isValidUrl(formValue.value.link)){
                    try{
                        const res = await axios({
                            method:'POST',
                            url:'links',
                            data:formValue.value
                        });

                        if(res.status === 201){
                            store.state.updateData = {
                                name:'add-new-link',
                                data: res.data.data.data
                            }
                        }
                        console.log(res);
                    }catch(err){    
                        console.log(err.response);
                        error.value = err.response.data.message;
                    }
                }else{
                    error.value="Your link is invalid";
                }
            }else{
                error.value="Elements are missing before submitting"
            }
        }

        const handleForm = (value) => {
            error.value='';
            formValue.value[value.field] = value.value;
            handleSubmitReady();
            console.log(value);
        }

        const formInputs = {
            category: {
                label:"Link Category",
                input:"select",
                placeholder:"Select link category",
                name:'type',
                options:[
                        {
                            value: undefined,
                            message: "--- Select link category ---",
                            disabled: true,
                            selected: true,
                        },
                        {
                            value: 'article',
                            message: "Article"
                        },
                        {
                            value: 'blog',
                            message: "Blog"
                        },
                        {
                            value: 'contact',
                            message: "Contact"
                        },
                        {
                            value: 'facebook',
                            message: "Facebook"
                        },
                        {
                            value: 'instagram',
                            message: "Instagram"
                        },
                        {
                            value: 'map',
                            message: "Map and tracks"
                        },
                        {
                            value: 'video',
                            message: "Video"
                        },
                        {
                            value: 'website',
                            message: "Website"
                        },

                    ],
            },
            name: {
                label:"Link name",
                input:"text",
                placeholder:"Example: Required gear list",
                name:'name'
            },
            link: {
                label:"Your link",
                input:"text",
                placeholder:"https://...",
                name:"link"
            }
        }

        return {
            data, 
            error,
            formInputs,
            handleForm,
            submitLink, 
            submitReady
        };
    }
}
</script>

<style lang="scss" scoped>
@import '../../style/main';
@import '../../style/components/popup';
</style>
<template>
    <header class="hd" :style="{backgroundImage:'linear-gradient(to right bottom, rgba(0,0,0,.1), rgba(0,0,0,.1)), url('+`${$store.state.apiUrl}/images/static/background/large/${data.background.split('.')[0]}.webp`+')', backgroundPosition: data.backgroundPosition ? data.backgroundPostion :'center'}">
        <div v-if="data.layout==='home'" class="hd__homepage">
            <div class="hd__homepage--content">
                <h1 class="hd__homepage--title">
                    {{data.title}}
                </h1>
                <h2 class="hd__homepage--subtitle" v-html="data.subtitle">
                </h2>
                <div class="hd__homepage--action">
                    <router-link :to="{name: action.link}" v-for="action in data.actions" :key="action.title" :class="`btn btn--${action.color} btn__spacing`">{{action.title}} </router-link>
                </div>
            </div>
        </div>
        <div v-if="data.layout==='page'" class="">
            <div class="hd__text">
                <h1 class="hd__heading">
                    <span class="hd__text--title">{{data.title}}</span>
                    <span class="hd__text--subtitle">{{data.subtitle}}</span>
                </h1>
                <div class="hd__action">

                    <div  v-for="action in data.actions" :key="action.svg" class="hd__action--btn" >
                      <router-link :to="{name: action.link}" v-if="action.link" :class="`btn btn--${action.color} btn__spacing btn--animated `" >
                        <div class="hd__action--content">
                            <Svg v-if="action.svg" className="hd__action--svg" :icon="action.svg"></Svg>
                            <span class="hd__action--span">{{action.title}}</span>
                        </div>	
                      </router-link>
                      <button v-if="action.scroll" :class="`btn btn--${action.color} btn__spacing btn--animated `" @click="scrollToElement(action.scroll)">
                        <div class="hd__action--content">
                            <Svg v-if="action.svg" className="hd__action--svg" :icon="action.svg"></Svg>
                            <span class="hd__action--span">{{action.title}}</span>
                        </div>	
                      </button>
                    </div>
                    
                </div>
        </div>'
        </div>
        <button v-if="data.scroll" class="hd__down" aria-label="scroll-down button" @click="scrollToElement(data.scroll)">
            <Svg :className="'hd__down--svg'" :icon="'arrow-down'"></Svg>
        </button>
    </header>

</template>

<script>
import {ref} from 'vue';
import Svg from './utils/Svg';

import scrollTo from '../composables/scroll'



export default {
    components:{Svg},
    props:['data'],
    setup(){
        const title="Trails";
        const user = ref('Celine');

        const scrollToElement = scrollTo;


        return {
            scrollToElement,
            title, 
            user
        };
    },
    computed:{
        gettingUser(){
            return this.$store.getters.getUser;
        }
    }
}
</script>

<style lang="scss">

@import '@/style/main';

.hd{
  margin-top: $nav-height;
  height: 65rem;

  filter: opacity(80%);

  background-size: cover;
  background-position: bottom; /*L'image reste centree (top, bottom, center)*/
  position: relative;

  @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 89%, 50% 100%, 0 89%);
    clip-path: polygon(0 0, 100% 0, 100% 89%, 50% 100%, 0 89%);
  }

  @include respond(phone){
    height: 80rem;
  }

  &__heading{
       color: $color-white;
        text-transform: uppercase;

        /*On enleve les bug de safari*/
        backface-visibility: hidden; /*Pour contrer l'effet shaky des animations*/
        -webkit-backface-visibility: hidden;
        transform-style: preserve-3d;
        -webkit-transform-style: preserve-3d;
        -webkit-font-smoothing: antialiased;

        margin-bottom: 2.5rem; /*Pour creer un espace blanc*/
  }


  &__text{
    position: absolute;
    top: 46%;
    left: 50%;

    max-width: $page-max-width;
    transform: translate(-50%, -50%); /*On shit de 50% de la largeur de la boite*/
    text-align: center;

    @include respond(phone) {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 95%;
    }

    &--title{
      display: block;
      font-size: 3.6rem;
      font-weight: 700;
      letter-spacing: 1.3rem;
  
      animation-name: moveInLeft;
      animation-duration: 1s;
      animation-timing-function: ease-out;
  
      text-shadow:1px 1px #222;
      margin-bottom: 1rem;
  
      @include respond(phone) {
        letter-spacing: 0.8rem;
        font-size: 2.8rem;
      }
    }
    &--subtitle{
      display: block; /*Un element bloc va occuper toute la largeur*/
      font-size: 2rem;
      font-weight: 400;
      letter-spacing: 1.2rem;

      animation: moveInRight 1s ease-out;
      text-shadow:1px 1px #777777;
      margin-bottom: 3rem;

      @include respond(phone) {
        letter-spacing: 0.5rem;
        font-size: 1.8rem;
      }
    }
  }

  &__action{
      display: flex;
      align-items: center;
      justify-content: center;
  
      @include respond(phone) {
        flex-direction: column;
      }

      &--btn{
          &:not(:last-child){
              margin-right: 2rem;
              @include respond(phone){
                margin-right: 0;
                margin-bottom: 1.5rem;
              }
          }
      }
        &--svg {
            @include svg(2.2rem);
            margin-right: 1rem;
        }

        &--content {
            display: flex;
            align-items: center;
            justify-content: center;
        }
  }

 &__down {
    position: absolute;
    top: 96%;
    left: 50%;

    transform: translate(-50%, -50%);
    color: $color-white;
    background-color: transparent;
    border: none;
    cursor: pointer;
    &--svg {
      @include svg(4rem);
      animation: bounce 2s infinite;
    }
    &:focus {
      border: none;
      outline: none;
    }
  }


  &__homepage{
    max-width: $page-max-width;
    margin: 0 auto;
    display: flex;
    align-items: center;
    height: 100%;

    padding: 0rem 0rem;

    @include respond(tab-land){
      padding: 0rem 3rem;
    }

    

    @include respond(phone){
      justify-content: center;
    }

    &--content{
      background-color: rgba($color-white, .9);
      box-shadow: $shadow;
      width: 40rem;
      padding: 3rem;

      @include respond(phone){
        width: 80%;
        margin-bottom: 25rem;
      }
      
    }
    &--title{
      font-size: 3.2rem;
      
      text-transform: uppercase;
      letter-spacing: .4rem;
      padding-bottom: 2.5rem;
      margin-bottom: 2.5rem;

      line-height: 4.1rem;
      color:$color-grey-90;

      text-align: left;

      /*Horizontal Dashed line*/
      background-image: linear-gradient(to right, currentColor 50%, rgba(255,255,255,0) 0%);
      background-position: bottom;
      background-size: 8px 1px;
      background-repeat: repeat-x;

      
    }
    &--subtitle{
      margin-bottom: 2.8rem;
      font-size: 1.6rem;
      font-weight: 400;
      letter-spacing: .1rem;

      @include respond(phone){
        font-size: 1.8rem;
      }
      & b{
        color:$color-blue-light;
        font-weight: 400;
      }
    }
    &--action{
      display: flex;
      justify-content: center;
    }
  }
}


</style>
  <template>
    <div class="authentication__container">
        <button class="authentication__close" @click="$store.commit('hideAuthentication')">
            <Svg className="authentication__close--svg" icon="delete"></Svg>
        </button>
        <div class="authentication__content">
            <contact-link :text="t('contact')"></contact-link>
            <h1 class="authentication__title">
                {{t(`${formName}.title`)}}
            </h1>
            <h2 class="authentication__subtitle">
                {{t(`${formName}.subtitle`)}}
            </h2>
            <h3 class="authentication__error">
                <span v-for="err in error" :key="err">{{err}}</span>
            </h3>
            <form v-if="formName==='login'" action="" class="authentication__form" method="POST" @keyup.enter="handleAuthentication(formName)">
                <div v-for="item in login" :key="item.name" class="form__item">
                    <text-input v-if="item.input==='text' || item.input=='password'" :inputdata="item" @updateForm="handleFormData"></text-input>
                </div>
            </form>
            <form v-if="formName==='signup'" action="" class="authentication__form" method="POST" v-on:key.enter="handleAuthentication(formName)">
                <div v-for="item in signup" :key="item.name" class="form__item">
                    <text-input v-if="item.input==='text' || item.input=='password'" :inputdata="item" @updateForm="handleFormData"></text-input>
                    <checkbox-input v-if="item.input==='checkbox'" :label="item.label" :name="item.name" @updateForm="handleFormData"></checkbox-input>
                </div>
            </form>
            <div class="authentication__actions">
                <button class="btn btn--blue btn--logo authentication__actions--btn" @click="handleAuthentication(formName)">
                    <loading-button :loading="submitLoading">{{t(`${formName}.submit`)}}</loading-button>
                </button>
                <button class="btn btn--facebook btn--logo authentication__actions--btn" @click="facebookLogin">
                    <Svg className="btn__svg--solo" icon="facebook"></Svg>
                </button>
                <button class="btn btn--google btn--logo authentication__actions--btn" @click="googleLogin">
                    <Svg className="btn__svg--solo" icon="google"></Svg>
                </button>
            </div>
            <button class="btn__text text-medium u-margin-top-3rem" v-html="t(`${formName}.switch`)" @click="$store.commit('switchAuthentication')"></button>
            <router-link :to="{name:'PasswordForgot'}" v-if="formName==='login'" class="btn__text text-medium u-margin-top-1rem" @click.native="$store.commit('hideAuthentication')" >
                {{t('passwordForgotten')}}
            </router-link>
        </div>
    </div>
</template>

<script>

import{onMounted, ref, loadScript} from 'vue';
import axios from 'axios';

import {loadFacebookApi, fbLogin} from '../composables/facebookLogin';

import CheckboxInput from './form/CheckboxInput';
import TextInput from './form/TextInput.vue';
import ContactLink from './form/ContactLink.vue';

import LoadingButton from './utils/LoadingButton';

import {useStore} from 'vuex';
import {useI18n} from 'vue-i18n';

import Svg from './utils/Svg';


import en from "@/locales/en/authentication.json";
import fr from "@/locales/fr/authentication.json";

export default {
    props: ['formName'],
    components:{ContactLink, CheckboxInput, LoadingButton, TextInput, Svg},
    setup(props){
        const data=ref({});
        const error = ref([]);

        const submitLoading = ref(false);

        const store = useStore();

        const {t, locale} = useI18n({
            messages: {
                en: en,
                fr: fr
            }
        }) 

        

        const login = [
                    {
                        "label": t('form.email.label'),
                        "placeholder": t('form.email.placeholder'),
                        "name":"email",
                        "input": "text",
                        "data": "coucou"
                    },
                    {
                        "label": t('form.password.label'),
                        "placeholder": t('form.password.placeholder'),
                        "name":"password",
                        "input": "password",
                        "data": ""
                    },
                ];
        const signup =  [
                    {
                        "label": t('form.email.label'),
                        "placeholder": t('form.email.placeholder'),
                        "name":"email",
                        "input": "text",
                    },
                    {
                        "label": t('form.password.label'),
                        "placeholder": t('form.password.placeholder'),
                        "name":"password",
                        "input": "password",
                    },
                    {
                        "label": t('form.passwordConfirm.label'),
                        "placeholder": t('form.passwordConfirm.placeholder'),
                        "name":"passwordConfirm",
                        "input": "password",
                    },
                    {
                        "label": "By subscribing, you agree with the storage and handling of your data by this website in accordance to our <a href='./privacy' class='btn__text u-color-blue-light'>Privacy Policy</a>",
                        "placeholder":"",
                        "name":"acceptConditions",
                        "input": "checkbox",
                    },
                ]

        onMounted(() => {
            loadFacebookApi();
        })

        const facebookLogin = async () => {
            FB.login(statusChangeCallback, {
                scope: "email,public_profile",
                return_scopes: true,
            });
        };

        const googleLogin = async() => {

            const loginScript = document.createElement('script')
            loginScript.setAttribute('id', 'google-login-script')
            loginScript.setAttribute('src', 'https://apis.google.com/js/platform.js')
            loginScript.setAttribute('async', true)
            loginScript.setAttribute('defer', true)
            document.head.appendChild(loginScript)

            loginScript.onload = () => {
                gapi.load('auth2', async function() {
                const GoogleAuth = await gapi.auth2.init({
                    client_id: process.env.VUE_APP_GOOGLE_SIGNUP
                    })
                const googleUser = await GoogleAuth.signIn();
                const basicProfile = googleUser.getBasicProfile();
      
                if(basicProfile){
                    try {
                            const res = await axios({
                                method: "POST",
                                url: "users/googleconnect",
                                data: {
                                    googleId: basicProfile.getId(),
                                    email: basicProfile.getEmail(),
                                    name: basicProfile.getName()
                                },
                            });

                            successLogin(res, 'login');
                        } catch (err) {
                            console.log(err);
                            submitLoading.value = false;
                        }
                }
            })
            };
            
        }
        
        const statusChangeCallback = async (res) => {
            if (res.status === "connected") {
                FB.api("/me",{ locale: "en_US", fields: "name, email, picture.type(large)" }, async function(response) {
                        try {
                            const res = await axios({
                                method: "POST",
                                url: "users/fbconnect",
                                data: response,
                            });

                            successLogin(res, 'login');
                        } catch (err) {
                            console.log(err);
                            submitLoading.value = false;
                        }
                    }
                );
            } else {
                console.log("Not authenticated");
            }
        };

        const handleFormData = (val) => {
            data.value[val.field] = val.value;
        }

        const handleAuthentication = async(form) => {

            //Loader
            submitLoading.value = true;

            if(form === 'login'){
                    try {
                        const res = await axios({
                            method: 'POST',
                            url: '/users/login',
                            data: data.value,
                        });
                        if (res.data.status == 'success') {
                            successLogin(res, 'login');
                        }
                    
                } catch (err) {
                    error.value = [err.response.data.message];
                    submitLoading.value = false;
                }
            }

            if(form === 'signup'){
                error.value = [];

                const fields = ['email', 'password', 'passwordConfirm', 'acceptConditions'];

                fields.forEach(el=>{
                    if(!data.value.hasOwnProperty(el)){
                        error.value.push(`${el} is missing`)
                        submitLoading.value = false;
                    }
                })

                if(!error.value.length){
                    if(data.value.password.length < 8){
                        error.value.push(t('error.passwordLength'));
                        submitLoading.value = false;
                    }

                    //Password identical
                    if(data.value.password !== data.value.passwordConfirm){
                        error.value.push(t('error.passwordIdentical'));
                        submitLoading.value = false;
                    }

                    if(data.value.acceptConditions !== true){
                        console.log(data.value.acceptConditions);
                        error.value.push(t('error.acceptConditions'));
                        submitLoading.value = false;
                    }
                }
                

                if(!error.value.length){
                    try{
                        const res = await axios({
                            method:'POST',
                            url: '/users/signup',
                            data: data.value
                        });
                        successLogin(res, 'signup', data.value.email);

                    }catch(err){
                        error.value = [err.response.data.message];
                        console.log(err.response);
                        submitLoading.value = false;
                    }
                }
                
                
            }
        }

        const successLogin = async (res, type, email = false) => {
            
            submitLoading.value = false;

            let message = '';
            if(type === 'login'){
                message = t('success.loggedIn');
            }
            
            if(type==='signup'){
                message = t('success.signUp', {email});
            }
-
            $cookies.set("jwt", res.data.token, "3M");
            await store.dispatch('loggedInSuccess');
            store.dispatch('showAction', {message, color:'green'});

            
        }

        

        return {data, error, facebookLogin, googleLogin, handleFormData, handleAuthentication, login, signup,submitLoading, t}
    },
}
</script>


<style lang="scss" scoped>

@import '@/style/main';
@import '@/style/components/authentication';



</style>
export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A props d'Hike On Earth"])},
  "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On vous raconte l'histoire derrière ce projet"])},
  "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrez notre histoire"])},
  "menu": {
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre objectif"])},
    "how": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment ça fonctionne ?"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'équipe"])},
    "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moments clés"])}
  },
  "goal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre objectif"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aider les randonneurs à <strong>se rencontrer</strong> et à trouver de nouvelles <strong>aventures à pied</strong>"])},
    "story": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment ça a commencé ?"])},
      "content": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In 2017, Hike On Earth founder Sébastien Goldberg set out to thru-hike the <a href='trail/pacific-crest-trail/'>Pacific Crest Trail</a>. This experience sparked his passion for hiking in the wilderness. The moment he was back home, Sébastien started looking for his next hiking adventure."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["But he struggled to find multi-day hikes and to convince friends or relatives to join in. In hindsight, he wished he could have teamed-up with other hikers to prepare and start new adventures. He soon found out that no website made that possible."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To address this, he founded Hike On Earth, a <span>collaborative trail database that helps hikers find and plan their next hiking adventure together</span>. Before long, Emeline and Ben brought their own ideas (and hiking shoes) into the project. Our small team was born!"])},
        
      ]
    }
  },
  "how": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment ça fonctionne ?"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Trouver</strong>, <strong>planifier</strong> et <strong>partager</strong> ses randonnées sur une seule et même plateforme"])},
    "data": [
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrir de nouvelles randonnées"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discovering new trails should not require long, strenuous hours of research. To help with that, Hike On Earth gathers long-distance trails from all over the world in one place. Hikes can be searched by country, duration and distance. Hikers, be prepared: you will find trails you have never heard of!"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planifier son aventure à pied"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An information toolkit is provided on each trail. It includes data on trail duration, difficulty, cost, useful links, and much more. All data is based on official sources or on information shared by hikers from the Hike On Earth community."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partager son expérience avec une communauté"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What a hiker learns on the trail can be gold to others. So anyone is welcome to add new trails, trail-specific information, tips and pictures into the platform. To ensure relevance, all data inputs are moderated by the Hike On Earth team."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouver des partenaires bien-veillants"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiking or preparing an adventure alone is not always the safest or most fun option. On Hike On Earth, hikers can meet, help each other, and find hiking partners for their next trail. But that’s not all: they can also seek advice from trail veterans who have already completed a particular trail."])}
      }
    ],
    "gear": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du matériel ultra-léger pour soutenir cette plateforme"])},
      "content": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We believe that affordable, high-quality light gear should not be available only in the United States. So we designed light hiking gear now available to all EU residents. We field-tested all our products ourselves, <a href='store/'>come take a look!</a>"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As Hike On Earth is entirely free and independent, our store is vital to ensure the platform’s sustainability. We encourage regular users to support us by visiting our store or <a href='store/'>making a donation</a>."])},
        
      ]
    }
  },
  "team": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'équipe"])},
    "people": [
      {
        "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fondeateur"])},
        "descr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Be who you are, do what you love</b><br> This quote is driving his life for these last few years. After graduating as electromechanical engineer, Sebastien was looking for something meaningful to do, something he loves. Today, he wants to share his passion with a strong and passionate community of hikers."])}
      },
      {
        "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designeuse"])},
        "descr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Young architect, Emeline uses her creative and artistic spirit to improve user experience. To her, the beauty of hiking starts as soon as a hiker browses the web and looks for his or her next adventure!"])}
      }
    ]
  },
  "timeline": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moments clés"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etapes clés du <strong>développement</strong> de notre plateform"])},
    "steps": [
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premier 'thru-hike'"])},
        "descr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Une fois diplomé, Sébastien Goldberg a décidé de partir découvrir le monde du thru-hike en allant randonner sur le magnifique sentier du Pacific Crest Trail. Emerveillé par l'expérience vécue et inspiré par ce monde autour de la randonnée qui existe aux USA, il pense qu'il y a du chemin à parcourir ici en Europe."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment continuer ?"])},
        "descr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De retour en Europe, Sébastien a du mal à trouver sa prochaine aventure et à trouver d'autres randonneurs enthousiastes. Sur ce, il décida de créer cette plateforme qui répondrait à son besoin."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Développement de la plateforme"])},
        "descr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sébastien a commencé à développer la plateforme depuis zéro, en apprenant sur le tas comment développer un tel site internet."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pré-lancement de la campagne"])},
        "descr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons rassemblé 30 randonneurs, ravis de tester le nouveau site web et donner leurs feedbacks."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hike on earth est en ligne"])},
        "descr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Après plusieurs mois de travail et plusieurs milliers de lignes de code, Hike On Earth est en ligne, accessible à tous."])}
      }
    ]
  }
}
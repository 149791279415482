<template>
  <Popup />
  <Navigation />  
  <router-view />
  <Footer/>

</template>

<script>
  // import {ref} from 'vue';  
  import Popup from './components/Popup'
  import Navigation from './components/navigation/Navigation'
  import Footer from './components/Footer'

  import {useStore} from 'vuex';

  import { useI18n } from 'vue-i18n'; 
  import { onMounted, watch } from 'vue';


  export default {
    components: { Navigation, Footer, Popup },
    setup(){
      const store = useStore();
      const {locale}  = useI18n();

      //Define language from change
      watch(store.state, (n, o) => {
        if(n.language) locale.value = n.language;
      })

      onMounted(() => {
        store.dispatch('autoLogin')
      })

    },

  }

</script>

<style lang="scss">

@import url('https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900&display=swap');
@import '@/style/main.scss';

*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
	-webkit-font-smoothing: antialiased;
}

#app {
  font-family: Lato, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color-grey-75;

  font-size: $default-font-size;

  box-sizing: border-box; /*Permet de dimensionner les box en incluant le padding */
	padding: 0rem; /*Mets une bordure au site*/

  ::selection {
    background-color: $color-blue-light;
    color: $color-white;
  }

}
</style>

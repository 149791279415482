<template>
  <div class="upload">
    <div class="upload__header">
      <template v-if="data.trail">
        <h2>
          Share the pictures of your adventures here
        </h2>
        <h3>
          {{data.trail}}
        </h3>
      </template>
      <template v-else-if="data.folder">
        <h2>
          Upload static images
        </h2>
        <h3 >
          {{data.folder}}
        </h3>
      </template>
    </div>

    <div class="upload__container">
      <div id="upload-errors" class="upload__error"></div>
      <label
        for="pic-upload"
        class="upload__container--label upload__container--label-full"
        :class="{'upload__container--label-drag' : dragOver}"
        @dragover.prevent="dragOver = true" 
        @dragleave="dragOver = false"
        @drop.prevent="onFileSelected"
        @dragenter.prevent
      >
        <div class="upload__container--content">
          <img
            :src="require('@/assets/img/illustrations/upload_pic.svg')"
            alt="file upload svg"
            class="upload__container--svg"
          />
          <h5 class="upload__container--text">
            Drop your images here or
            <span>browse</span>
          </h5>
          <input
            type="file"
            id="pic-upload"
            name="attachments[]"
            class="upload__container--input"
            multiple
            accept="image/jpeg"
            @change="onFileSelected"
            
          />
        </div>
      </label>
      <div  v-if="displayUploadingImages.length" class="upload__container--uploaded">
          <div v-for="col in displayUploadingImages" :key="col.id" class="upload__col" :data-id="col.id">
              <uploading-picture v-for="(pic, i) in col.content" :key="`pic-${i}`" :image="pic.reader" :loaded="pic.loaded" :id="pic.id" @delete="deleteImage"></uploading-picture>
          </div>
      </div>
    </div>
    <div class="upload__footer">
      <button class="btn btn--blue-i" @click="$store.state.popup.display=false">
          Cancel
      </button>
      <button class="btn" :class="{'btn--grey upload__footer--nosubmit': !submitReady, 'btn--blue upload__footer--submit': submitReady}" @click="submitPictures">
          <loading-button :loading="isSubmitted">Submit</loading-button>
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useStore, commit } from 'vuex';

import axios from 'axios';
import $ from 'jquery';

import Svg from '../utils/Svg';
import UploadingPicture from '../pictures/UploadingPicture';
import LoadingButton from '../utils/LoadingButton';

export default {
    components: {LoadingButton, Svg, UploadingPicture},
    props:['name'],
    setup(){
        const store = useStore();
        const data = store.state.popup.data;
        const uploadedImages = ref([]);
        const displayUploadingImages = ref([]);
        const submitReady = ref(false);
        const isSubmitted = ref(false);
        const dragOver= ref(false);

        const onFileSelected = async (e) => {

          let newImages = [];

          if(e.type === 'drop'){
                dragOver.value = false;
                if(e.dataTransfer.files.length){
                  e.dataTransfer.files.forEach(pic => {
                      uploadedImages.value.push(pic);
                      newImages.push(pic);

                  });
                }
            }

          if(e.type === 'change'){
              if(e.target.files.length){
                  e.target.files.forEach(pic => {
                      uploadedImages.value.push(pic);
                      newImages.push(pic);

                  });
              }
          }

          if(uploadedImages.value.length && !submitReady.value) submitReady.value = true
          

          //Created Preview
          newImages.forEach(async picture => {
              const reader= new FileReader();
                reader.readAsDataURL(picture)
                reader.onload = function(e) {
                        picture.reader = e.target.result
                        picture.loaded = 0;
                        picture.id = Math.floor(Math.random()*10000000000);
                        displayPreviewPic(picture);
                    }
          })
        }


        const displayPreviewPic = (pic) => {
                const cols = 3;
                let smaller = {
                    height: 0,
                    id: -1
                }
                if(!displayUploadingImages.value.length){
                    for(let i=0; i<cols; i++){
                        displayUploadingImages.value.push({
                            id: i,
                            height: 0,
                            content: []
                        })
                    }
                    smaller.id = 0;
                }else{
                    displayUploadingImages.value.forEach(el=>{
                        el.height = $(`.upload__col[data-id='${el.id}']`).height();
                        smaller.id<0 || smaller.height>el.height? smaller={id: el.id, height: el.height} : false
                    })
                }
                displayUploadingImages.value[smaller.id].content.push(pic);
            }

        const deleteImage = (idPic) => {
          $("#uploading-pic-"+idPic).remove();
          uploadedImages.value = uploadedImages.value.filter(el => el.id !== idPic); 

          if(!uploadedImages.value.length) displayUploadingImages.value=[]; submitReady.value = false;
        }

        const submitPictures = async () => {
          if(!uploadedImages.value.length) return false;

          isSubmitted.value = true;

          let urlPost = "pictures";
          
          if(data.name === 'upload-static-image') urlPost += '/static/'+data.folder

          await Promise.all(uploadedImages.value.map(async pic => {
            //Preparing data
            const formData = new FormData();
            
            if(data.name ==='upload-pictures') {
              formData.append('trail', data.id)
              formData.append('image', pic)
            }else if(data.name ==='upload-static-image'){
              formData.append('image', pic)
            }


            try{
              const res = await axios({
                method:"POST",
                url: urlPost,
                data: formData,
                onUploadProgress: uploadEvent => {
                  const uploaded = Math.round(uploadEvent.loaded* 100/uploadEvent.total);
                  $(".upload__picture--loader[data-id='"+pic.id+"']").attr('class', `upload__picture--loader upload__picture--loader-${100-uploaded}` )
                  $(".upload__picture--loaded[data-id='"+pic.id+"']").attr('class', 'upload__picture--loaded upload__picture--loaded-'+uploaded)
                },
              })
              console.log(res);
            }catch(err) {
              console.log(err.response)
            }
          }));

          // ACTION EVERYTHING IS GOOD
          store.commit("successPopup", {name:"success", title:"Thank you for your contribution !", message:"Your pictures are being reviewed. We will send you an email when they are posted online!"})

        }
        return{
            data,
            deleteImage,
            displayUploadingImages,
            dragOver,
            isSubmitted,
            onFileSelected,
            submitPictures,
            submitReady,
            uploadedImages
        }
    }
}
</script>

<style lang="scss" scoped>

@import '@/style/main';
.upload {
  display: flex;
  flex-direction: column;
  &__header {
    display: flex;
    padding: 2rem;
    align-items: center;
    justify-content: space-between;
    font-size: 1.6rem;
    text-transform: uppercase;
    letter-spacing: .1rem;
    background-color: $color-blue-light;
    color:$color-white;

    & h2, h3 {
        font:inherit
    }

    @include respond(phone) {
      min-height: 6rem;
    }
  }
  &__error {
    display: flex;
    width: 100%;

    &--text {
      background-color: red;
      width: 100%;
      border: 1px solid $color-red-light;
      font-size: 1.6rem;
      margin: 2rem 0;
    }
    &--li {
      list-style: none;
      padding: 1rem;
      &::before {
        content: "-";
        margin-right: 1rem;
      }
    }
  }
  &__container {
    padding: 2rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    position: relative;
    min-height: 50vh;
    max-height: 75vh;

    &--label {
        flex: 1;
      height: 100%;
      width: 100%;
      border: 2px dashed $color-grey-75;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
      cursor: pointer;
      &:hover {
        background-color: $color-grey-05;
      }
      &-small {
        height: 30%;
      }
      &-drag {
        background-color: darken($color-grey-05, 10);
      }
    }
    &--uploaded {
      flex: 1;
      height: 100%;
      padding: 2rem 0;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      @include respond(phone) {
        flex-direction: column;
      }
    }
    &--content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;
      padding: 2rem;
    }
    &--text {
      display: flex;
      font-weight: 400;
      & span{
          color: $color-blue-light;
          margin-left: 0.5rem;
          text-decoration: underline;
      }
    }

    &--input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
    &--svg {
      width: 8rem;
      height: auto;
    }

  }

  &__footer {
    height: 7rem;
    padding: 2rem;
    border-top: 1px solid $color-grey-75;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &--submit {
      margin-left: 1rem;
    }
    &--nosubmit {
      cursor: not-allowed;
      margin-left: 1rem;
    }
  }

  &__col {
    flex: 0 0 32%;
    display: flex;
    flex-direction: column;
   
  }
  &__delete {
    opacity: 0;
    position: absolute;
    right: 0.8rem;
    top: 0.8rem;
    z-index: 1000;
    color: $color-white;
    border-radius: 50%;
    background-color: $color-grey-75;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    transition: all 0.2s;
    &--svg {
      @include svg(1rem);
    }
    &:hover {
      &::after {
        content: "Remove";
        position: absolute;
        padding: 0.3rem 0.6rem;
        border-radius: 3px;
        text-transform: uppercase;
        font-size: 1rem;
        right: 100%;
        top: 50%;
        margin-right: 0.5rem;
        transform: translate(0%, -50%);
        background-color: $color-grey-75;
        color: $color-white;
      }
    }
  }
  &__col--img-container:hover > &__delete {
    opacity: 1;
  }

  &__profile {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    &--save {
      width: 100%;
      display: flex;
      justify-content: center;
      position: absolute !important;
      top: 85%;
      left: 50%;
      transform: translateX(-50%);
    }
    &--img {
      width: 100%;
      height: auto;

      max-width: 100%;
      max-height: 100%;
      object-fit: contain;

      &-container {
        position: relative;
        height: auto;
        width: 50%;
        display: flex;
      }
    }
  }
  &__horizontal {
    margin-top: 4rem;
    padding: 1rem 0;
    display: flex;
    max-width: 100%;
    overflow-x: scroll;

    &--item {
      display: block;
      height: 8rem;
      white-space: nowrap;
      width: 8rem;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
    &--img {
      height: 100%;
      width: auto;
    }
  }
}


</style>
export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aidez-nous à améliorer cette plateforme"])},
  "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre avis nous intéresse !"])},
  "descr": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous sommes une petite équipe de randonneurs passionés et il est impossible de penser à tout. Votre feedback nous aidera à se concentrer sur ce qui peut réellement vous aider à vivre des aventures de rêve."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez trouvé une information erronée, un bug durant votre navigation ou avez de super idées sur ce qu'on devrait ajouter/modifier ? C'est sur cette page que nous récoltons toutes vos commentaires."])},
    
  ],
  "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partagez votre feedback"])},
  "feedbacktitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos idées et commentaires"])},
  "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback public"])},
  "needlogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez être connectés pour partager un feedback avec nous !"])},
  "publictitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires publics"])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre feedback a été partagé avec succes"])}
}
<template>
<div>
      <div class="popup__header popup__header--blue popup__header--split">
          <h2 class="popup__header--title">
              <Svg className="popup__header--title-svg" :icon="data.surveyIcon"/>
              {{data.surveyTopic}}
          </h2>
         
          <h4 class="popup__header--subtitle">
             {{data.trailName}}
          </h4>
      </div>
      <div class="popup__main">

          <div class="popup__main--title">
              <h2>
                Avergage : {{data.avg}} {{data.type==="rating"? `/${data.maxInput}` : data.surveyUnit}}
              </h2>
              <h3>
                  ({{data.votes}} votes)
              </h3>
              
          </div>
           <div v-for="response in responses" :key="response._id" class="survey__like--item">
                <img :src="`${$store.state.apiUrl}/images/profil/small/${response.createdBy.profilePicture.split('.')[0]}.webp`" alt="user picture" class="survey__like--photo">
                <div class="survey__like--content">
                    <div class="survey__like--name">
                        {{response.createdBy.name}}
                    </div>
                    <div class="survey__like--date">
                        {{moment(response.createdAt, 'YYYY-MM-DD').format('LL')}}
                    </div>
                    <div class="survey__like--comment">
                        {{response.comment}}
                    </div>
                </div>
                <div class="survey__like--cote">
                    <Rating v-if="data.surveyType==='rating'" :cote="response.rating" :maxRating="data.maxInput" icon="star"/>
                    <div v-else>
                        {{response.rating}} {{data.surveyUnit}}
                    </div>
                </div>
            </div>
            <div class="popup__action">
              <button class="btn btn--blue-i popup__action--item" @click="$store.state.popup.display = false">
                  Cancel
              </button>
                <button v-if="!data.userVote" class="btn btn--blue popup__action--item" @click="actionPopup">
                  Vote
              </button>
          </div>
    </div>
    
</div>
</template>

<script>
import { useStore } from 'vuex';
import Svg from '../utils/Svg';
import Rating from '../utils/Rating';

import axios from 'axios';
import moment from 'moment';
import { ref } from 'vue';

export default {
    components:{
        Rating,
        Svg
    },
    setup(){
        const store =useStore();
        const responses = ref([]);
        const data = store.state.popup.data.data;

        

        const fetchResponses = async () => {
            try{
                console.log(data.idSurvey, data.idTrail);
                const res = await axios({
                    method:"GET",
                    url:`surveys/response/${data.idSurvey}/trail/${data.idTrail}`,
                })

                console.log(res.data);
                responses.value = res.data.data;
                console.log(responses.value);
            }catch(err){
                console.log(err.response);
            }
        }

        const actionPopup = () => {
            store.state.popup.data = data.voteData;
        }

        fetchResponses();
 

        return{
            actionPopup,
            data,
            moment,
            responses
        }
    }

}
</script>

<style lang="scss" scoped>
@import '../../style/main';
@import '../../style/components/popup';

.survey {
  &__like {
    &--title {
      color: $color-blue-light;
      font-size: 2.4rem;
      letter-spacing: 0.1rem;
      text-transform: uppercase;
    }
    &--img {
      color: $color-blue-light;
      margin-bottom: 0.8rem;
    }
    &--svg {
      @include svg(6rem);
    }
    &--avg {
      font-size: 1.8rem;
    }
    &--container {
      margin: 2rem 0;
      width: 100%;
    }
    &--photo {
      border-radius: 50%;
      width: 8rem;
      height: auto;
      margin-right: 2rem;

      @include respond(phone) {
        margin-right: 0;
        margin-bottom: 2rem;
      }
    }
    &--item {
      display: flex;
      align-items: center;
      box-shadow: $shadow;
      background-color: $color-white;
      padding: 2rem;
      color: inherit;
      text-decoration: none;
      width: 100%;
      &:not(:last-child) {
        margin-bottom: 2rem;
      }

      @include respond(phone) {
        flex-direction: column;
      }
    }

    &--content {
      flex: 1;
      @include respond(phone) {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
      }
    }
    &--name {
      font-size: 1.8rem;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.1rem;
      @include respond(phone) {
        margin-bottom: 0.7rem;
      }
    }
    &--date {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
      @include respond(phone) {
        margin-bottom: 0.9rem;
      }
    }
    &--comment {
      font-size: 1.4rem;
      @include respond(phone) {
        margin-bottom: 0.7rem;
      }
    }

    &--cote {
      color: $color-blue-light;
      display: flex;
      align-items: center;
      margin-left: 2rem;
      font-size: 2rem;
      &-item {
        &:not(:last-child) {
          margin-right: 0.3rem;
        }
      }
      &-svg {
        @include svg(2.6rem);
        &-empty {
          fill: transparent;
          stroke: currentColor;
          stroke-width: 0.8rem;
        }
      }
    }
  }
}



</style>
<template>
    <footer id="footer" class="footer">
		
        <div class="footer__container">
			<language-picker></language-picker>
            <div class="footer__logo">
                <router-link :to="{name:'Home'}" class="footer__logo--btn" aria-label="Hike On Earth">
					<Svg className="footer__logo--svg" icon="hoe"></Svg>
                </router-link>
                <ul class="footer__list">
                    <li v-for="social in footer.social" :key="social.name" class="footer__item">
                        <a :href="social.href" target="_BLANK" class="footer__link-logo" :aria-label="social.name">
                            <Svg :className="'footer__social'" :icon="social.svg"></Svg> 
                        </a>
                    </li>
                </ul>
            </div>
            <div class="footer__login-bouton">
                <ul class="footer__list">
                    <template v-if="!$store.state.loggedIn">
                        <li class="footer__item">
                            <button class="btn btn--white footer__btn--main" @click="$store.commit('showSignupPopup')">
                                {{t('auth.signup')}}
                            </button>
                        </li>
                        <li class="footer__item">
                            <button class="btn footer__btn--second" @click="$store.commit('showLoginPopup')">
                                {{t('auth.login')}}
                            </button>
                        </li>
                    </template>
                    <template v-else>
                        <li class="footer__item">
                            <router-link :to="{'name': 'Me', params:{page:'profile'}}" class="btn btn--white footer__btn--main">
                                {{t('auth.profile')}}
                            </router-link>
                        </li>
                        <li class="footer__item">
                            <button class="btn footer__btn--second" @click="logout">
                                {{t('auth.logout')}}
                            </button>
                        </li>
                    </template>
                </ul>
            </div>

            <div class="footer__menu">
                <div class="footer__menu--descr">
                    <div class="footer__col footer__col--flex">
                        <div class="footer__col--title">
                                {{footer.description.name}}
                        </div>
                        <div class="footer__col--content">
                            <div class="footer__col--text">
                                {{footer.description.text}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer__menu--links">
                    <div v-for="col in footer.menu" :key="col.title" class="footer__col">
                        <div class="footer__col--title">
                            {{col.title}}
                        </div>
                        <div class="footer__col--content">
                            <router-link v-for="link in col.links" :key="link.name" :to="link.to" class="footer__col--link">
                                {{link.name}}
                            </router-link>
                            <router-link :to="col.superlink.to" class="footer__col--superlink">
                                <Svg :className="'footer__col--superlink-svg'" :icon="'arrow-right-2'"></Svg>
                                {{col.superlink.name}}
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer__line">
            <div class="footer__container">
                <div class="footer__line--hiker">
					<Svg className="footer__line--hiker-svg" icon="hiker"></Svg>
                </div>
            </div>
        </div>
        
        <div class="footer__container">
            <div class="footer__bottom">
                <div class="footer__bottom--copyright">
                    <span>&copy; Hike On Earth {{new Date().getFullYear() }}</span>
                    <span>{{footer.adress}} <img :src="`${$store.state.apiUrl}/images/flag/thumbnail/be.jpeg`" width="550" height="477" alt="flag belgium" class="footer__bottom--copyright-flag"></span>
                </div>
                <div class="footer__bottom--legal">
                    <!-- <a href="/support/" class="footer__bottom--link"><span>Support Us</span></a> -->
                    <router-link :to="{name:'Terms'}" class="footer__bottom--link"><span>{{t('legal.terms')}}</span></router-link>
                    <router-link :to="{name:'Privacy'}" class="footer__bottom--link"><span>{{t('legal.privacy')}}</span></router-link>
                </div>
            </div>
        </div>
    </footer>
  
</template>

<script>

import Svg from './utils/Svg';
import {logout} from '@/composables/authentication/logout';
import LanguagePicker from './footer/LanguagePicker.vue';
import { useI18n } from 'vue-i18n';
import { computed, onMounted, watch } from 'vue';


import en from "@/locales/en/footer.json";
import fr from "@/locales/fr/footer.json";

export default {
    components: {
		Svg,
		LanguagePicker
	},
    setup(){
		
		const {t, locale}  = useI18n({
			messages:{
              en: en,
              fr: fr
            }
		})


        const footer = computed(() => {
			return {
				adress: t('project'),
				social:	[
						{
							"name"	:	"facebook",
							"svg"	:	"facebook",
							"href"	:	"https://www.facebook.com/hikeonearth/"
						},
						{
							"name"	:	"instagram",
							"svg"	:	"instagram",
							"href"	:	"https://www.instagram.com/hikeonearth/"
						},
						{
							"name"	:	"email",
							"svg"	:	"email",
							"href"	:	"mailto:info@hikeonearth.com"
						}
					],
				description	:	{
					"name"	:	"Hike On Earth",
					"text"	:	t('description')
				},
				menu	:	[
					{
						"title"	:	t('menu.heading.trails'),
						"links"	:	[
							{
								"name"	:	t('menu.links.findtrail'),
								"to":{
									name:'Trails',
								}
							},
							{
								"name"	:	t('menu.links.topeurope'),
								"target"	:	"Trails",
								"to":{
									name:'Trails',
									params:{
										queryString:'top-trails-in-europe'
									}
								}
							},
							{
								"name"	:	t('menu.links.newtrails'),
								"to":{
									name:'Trails',
								}
							},
							{
								"name"	:	t('menu.links.gallery'),
								"to":{
									name:'Trails',
									params:{
										queryString:'gallery'
									}
									
								}
							}
						],
						"superlink"	:	{
							"name"	:	t('menu.links.sharetrail'),
							"to":{
									name:'Contribute',
								}
						}
					},
					{
						"title"	:	t('menu.heading.community'),
						"links"	:	[
							{
								"name"	:	t('menu.links.popularpost'),
								"to":{
									name:'Community',
								}
							},
							{
								"name"	:	t('menu.links.latestpost'),
								"to":{
									name:'Community',
								}
							},
							{
								"name"	:	t('menu.links.foodpost'),
								"to":{
									name:'Community',
								}
							},
							{
								"name"	:	t('menu.links.gearpost'),
								"to":{
									name:'Community',
								}
							}
						],
						"superlink"	:	{
							"name"	:	t('menu.links.ask'),
							"to":{
									name:'Contribute',
								}
						}
					},
					{
						"title"	:	t('menu.heading.us'),
						"links"	:	[
							{
								"name"	:	t('menu.links.story'),
								"to":{
									name:'About',
								}
							}
						],
						"superlink"	:	{
							"name"	:	t('menu.links.feedback'),
							"to":{
									name:'Feedback',
								}
						}
					}
				]
			}});

		 
        return{footer, logout, t};
    }
}
</script>

<style lang="scss" scoped>
    @import '@/style/main';

.footer {
	position: relative;
	background-color: $color-blue-dark; // #004065; // $color-grey-dark-3;

	// border-top: 2px solid $color-blue-light;
	background-image: linear-gradient(to bottom right, #555, #333);

	padding: 6rem 0rem 3rem 0rem;
	font-size: 1.5rem;
	color: $color-white;

	@include respond(tab-port) {
		padding: 2rem 0;
	}

	&__container {
		position: relative;
		padding: 0 2rem;
		margin: 0 auto;
		max-width: $page-max-width;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		@include respond(tab-port) {
			margin-top: 3rem;
			flex-direction: column;
			justify-content: center;
		}
	}





	&__logo {
		text-align: center;
		margin-bottom: 2rem;

		@include respond(tab-port) {
			margin-bottom: 2rem;
		}

		&--svg{
			@include svg(25rem);
			height: 12rem;
			@include respond(tab-port) {
				margin-bottom: 1rem;
			}
		}
		&--btn {
			color: inherit;
			
		}
	}

	&__btn{
		&--main{
			background-color: $color-white;
			color:$color-grey-75
		}
		&--second{
			color:$color-white;
			border: 1px solid currentColor !important;
		}
	}

	&__menu{
		display: flex;

		@include respond(phone){
			flex-direction: column;
		}

		&--descr{
			flex: 1;
			margin-right: 3rem;

			@include respond(phone){
				margin-right: 0;
				margin-bottom: 3rem;
			}
		}

		&--links{
			display: flex;
			@include respond(phone){
				justify-content: space-between;
				flex-direction: column;
			}
		}


	}

	&__col{
		&:not(:last-child){
			margin-right: 3rem;
		}
		@include respond(phone){
			&:not(:last-child){
				margin-right: 0;
				margin-bottom: 3rem;
			}
		}
		&--flex{
			width: auto;
		}
		&--title{
			font-size: 1.4rem;
			text-transform: uppercase;
			letter-spacing: .1rem;
			margin-bottom: .8rem;
			font-size: 700;

			@include respond(phone){
				font-size: 1.8rem;
				margin-bottom: 1.6rem;
			}
		}
		&--content{
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;

			border-left: 2px solid currentColor;
			padding-left: 1rem;

			@include respond(phone){
				padding-left: 1.8rem;
			}
		}

		&--link{
			font-size: 1.2rem;
			color:$color-grey-15;
			text-transform: uppercase;
			letter-spacing: .1rem;
			text-decoration: none;
			&:not(:last-child){
				margin-bottom: 1rem;
			}

			@include hoverUnderline;

			@include respond(phone){
				font-size: 1.6rem;
				&:not(:last-child){
					margin-bottom: 2rem;
				}
			}
		}

		&--superlink{
			display: flex;
			color:inherit;
			text-decoration: none;
			align-items: center;
			text-transform: uppercase;
			letter-spacing: .1rem;
			font-size: 1.2rem;
			@include respond(phone){
				font-size: 1.6rem;
			}
			&-svg{
				margin-right: 1.2rem;
				color:$color-orange-dark;
				@include svg(1.6rem);
				transition: all .2s;
			}

			&:hover > &-svg{
				transform: scaleX(-1) rotate(180deg) translateX(.4rem);
			}
		}
		&--text{
			font-size: 1.4rem;
			font-weight: 300;
			color:$color-grey-10;
			text-align: justify;

			@include respond(phone){
				font-size: 1.6rem;
				line-height: 1.8rem;
			}
		}
	}

	&__link-logo {
		&:link,
		&:visited {
			color: inherit;
			text-decoration: none;
			text-transform: uppercase;
			display: inline-block;

			transition: all 0.2s;

			
		}

		&:hover,
		&:active {
			transform: rotate(5deg) scale(1.3);
		}
	}
	&__social {
		@include svg(4rem);

		@include respond(phone){
			@include svg(4.4rem);
		}
		&:hover {
			fill: $color-white;
			transform: rotate(180deg) scaleX(-1) rotate(0deg);
		}
	}

	&__login-bouton {
		text-align: center;
		margin-bottom: 4rem;

		@include respond(tab-port) {
			margin-bottom: 2rem;
		}
	}

	&__navigation {
		border-left: 2px solid $color-white;
		padding-left: 2rem;
		display: block;

		@include respond(tab-port) {
			text-align: center;
			border-left: none;
		}
	}

	&__navtitle {
		display: block;
		font-size: 2rem;
		color: $color-white;
		margin-bottom: 1.5rem;
		font-weight: 500;
		letter-spacing: 0.15rem;
		text-transform: uppercase;

		@include respond(tab-port) {
			text-align: center;
		}
	}

	&__list {
		list-style: none;
	}

	&__item {
		display: inline-block;
		letter-spacing: 0.2rem;

		&:not(:last-child) {
			margin-right: 1.8rem;
			@include respond(phone){
				margin-right: 2.2rem;
			}
		}

	}

	&__item-navigation {
		// display: block;
		letter-spacing: 0.2rem;
		font-weight: 300;
		&:not(:last-child) {
			margin-bottom: 0.3rem;
			padding-bottom: 1rem;
		}
	}

	&__link-text {
		&:link,
		&:visited {
			color: inherit;
			text-decoration: none;
			text-transform: uppercase;
			display: inline-block;

			transition: all 0.2s;

			@include hoverUnderline
		}
	}


	&__webshop {
		display: flex;
		justify-content: center;
		align-items: center;

		background-color: $color-orange-dark;
		color: inherit;
		text-decoration: none;
		padding: 1rem 2rem;

		font-size: 2rem;

		margin-top: 2rem;

		border-radius: 5px;

		text-transform: uppercase;
		letter-spacing: 0.3rem;

		transition: all 0.2s;

		&:hover {
			transform: scale(1.05) translateY(-0.2rem);
			box-shadow: 0 1rem 2rem rgba($color-black, 0.4);
		}

		@include respond(phone) {
			font-size: 1.6rem;
			padding: 0.6rem 1.2rem;
		}
	}

	
	&__line{
		position: relative;
		
		width: 100%;
		border-bottom: 3px solid $color-white;
		margin-top: 2rem;
		display: flex;
		justify-content: flex-start;
		align-items: flex-end;
		&--hiker{
			width: 100%;
			display: flex;
			&-svg{
				@include svg(6rem);
			}
		}
	}


	&__bottom {
		display: flex;
		
		padding-top: 1.5rem;
		position: relative;

		@include respond(phone){
			padding-top: 0;
			flex-direction: column-reverse;
		}
		

		&--copyright{
			display: flex;
			
			font-size: 1.4rem;
			text-transform: uppercase;
			letter-spacing: 0.1rem;
			margin-right: auto;
	
			@include respond(tab-port) {
				width: 100%;
			}

			@include respond(phone){
				margin-top: 3rem;
				flex-direction: column-reverse;
			}
			&-flag{
				height: 1.6rem;
				width: auto;
				margin-left: .8rem;
			}

			& span{
				display: flex;
				align-items: center;
				

				&:not(:last-child){
					padding-right: 1rem;	
					&::after{
						padding-left: 1rem;
						content: "\2022";

					}
				}
				@include respond(phone){
					font-size: 1.6rem;
					margin-bottom: 1.6rem;
					&:not(:last-child){
						padding-right: 0rem;
						padding-bottom: 1.5rem;
						&::after{
							padding-left: 0rem;
							content: "";
	
						}
					}
					
				}
			}
		}

		&--legal{
			display: flex;

			@include respond(phone){
				justify-content: flex-end;
			}
		}

		&--link{
			color:inherit;
			text-decoration: none;
			font-size: 1.4rem;
			text-transform: uppercase;
			letter-spacing: .1rem;

			list-style: none;
			display: flex;

			&:not(:first-child){
				padding-left: 1rem;
				&::before{
					padding-right: 1rem;
					content: "\2022";
				}
			}
			& span{
				@include hoverUnderline;
				@include respond(phone){
					font-size: 1.6rem;
				}
			}
		}

		
	}
}



</style>
<template>
  <div class="footer__language">
        <button class="footer__language--button" @click="displayList = !displayList">
            <Svg className="footer__language--button-svg" icon="world"/>
            <span id="footer-lang-selected" class="footer__language--button-lang" data-lang="fr">
                {{languages.filter(el=>el.val === locale)[0].name}}
            </span>
            <Svg className="footer__language--button-svg" icon="arrow-down"/>
        </button>
        <ul v-if="displayList" class="footer__language--options">
            <li v-for="lang in languages" :key="lang.val" class="footer__language--li" @click="setLanguage(lang.val)">
                {{lang.name}}
            </li>
        </ul>
    </div>
</template>

<script>
import { ref } from 'vue';
import Svg from '../utils/Svg';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import {changeLanguage} from '@/composables/languages.js';

export default {
    components:{
        Svg
    },
    setup(){
        const displayList = ref(false);
        const store = useStore();
        const route = useRoute();
        const router = useRouter();

        const {t, locale} = useI18n();

        const languages = [
            {
                name:"English",
                val : 'en'
            },{
                name:"Français",
                val:'fr'
            }
        ]

        const setLanguage = (lang) =>{
            changeLanguage(store, route, lang);
            displayList.value = false;
        }

        return {
            setLanguage,
            displayList,
            languages,
            locale
        }
    }
}
</script>

<style lang="scss">
@import '@/style/main';
.footer{
    &__language {
		position: absolute;
		top: 0rem;
		right: 0rem;

        @include respond(phone){
            position: relative;
            right: unset;
            top: unset;
            // width: 100%;
        }

		&--button {
			position: relative;
			padding: 0.8rem 1.2rem;
			border: 1px solid $color-white;
			background-color: transparent;
			color: $color-white;
			border-radius: 3px;

			outline: none;

			display: flex;
			align-items: center;

			cursor: pointer;
			&-svg {
				@include svg(1.4rem);
			}
			&-lang {
				font-size: 1.6rem;
				letter-spacing: 0.1rem;
				margin: 0rem 2rem 0rem 1.4rem;
			}

			transition: all 0.2s;
			&:hover {
				background-color: rgba($color-white, .2);
			}

            @include respond(phone){
                width: 100%;
                justify-content: center;
            }
		}
		&--options {
			width: 100%;
			padding: 0.2rem 0;
			margin: 0.5rem 0;
			background-color: rgba($color-white, .2);
			border:1px solid $color-white;
			border-radius: 3px;
		}
		&--li {
			list-style: none;
			padding: 0.7rem 1.3rem;
			cursor: pointer;
			&:not(:last-child){
				border-bottom: 1px solid currentColor;
			}
			&:hover {
				background-color: $color-grey-65;
			}
		}
	}
}

</style>
<template>
  <div v-if="!isLoading">
    <slot></slot>
    </div>
  <div v-else>
      <div class="loader"></div>
  </div>
</template>

<script>
import { ref, watch } from 'vue'

export default {
    props:["text", "loading"],
    setup(props){
      const isLoading = ref(props.loading);

      watch(()=> props.loading, (newVal, oldVal)=> {
        isLoading.value = newVal;
      })

      return {isLoading};
    }
}
</script>

<style lang="scss">

@import '../../style/main';

.loader {
  border: .4rem solid #f3f3f3; /* Light grey */
  border-top: .4rem solid $color-blue; /* Blue */
  border-radius: 50%;
  width: 2.4rem;
  height: 2.4rem;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>